<template>
    <div>
        <div class="row">
            <!-- logged in -->
            <div class="col-sm-12 mt-3" v-if="loggedIn">
                <div class="text-right">
                    Welcome,&nbsp;
                    {{ userProfile.contactName }}
                    &nbsp;|&nbsp;
                    <router-link to="/dashboard">
                        <a>Self Service Portal</a> </router-link
                    >&nbsp;|&nbsp;
                    <a href="#" @click="userSignOut">Sign out</a>
                </div>
            </div>
            <!-- not logged in -->
            <div class="col-sm-12 mt-3" v-else>
                <div class="text-right">
                    <router-link to="/signUp">
                        <a>Join DataPorts</a>&nbsp;|&nbsp;
                    </router-link>
                    <router-link to="/signIn">
                        <a href="#">Sign in</a>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="search-engine-positioning">
            <search-engine></search-engine>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import searchEngine from "./searchEngine.vue";
export default {
    name: "dataportsWebsite",
    components: {
        searchEngine,
    },
    data() {
        return {
            loggedIn: false,
        };
    },
    methods: {
        checkUserSession() {
            if (this.userProfile.contactName.length) {
                this.loggedIn = true;
            } else {
                this.loggedIn = false;
            }
        },
        userSignOut() {
            this.loggedIn = false;
            window.sessionStorage.clear();
        },
        requestDataportAccess() {
            console.log("Requesting access");
        },
    },
    mounted() {
        this.checkUserSession();
    },
    computed: {
        ...mapGetters({
            userProfile: "user/getUserProfile",
        }),
    },
};
</script>
<style scoped>
.search-engine-positioning {
    margin-top: 10%;
}
</style>

