import Vue from 'vue'
import VueRouter from 'vue-router'
import dashBoard from "../../components/dashboard.vue"
import myDataports from "../../components/myDataports.vue"
import editMyDataport from "../../components/editMyDataport.vue"
import organisation from '../../components/organisation.vue'
import addDataport from '../../components/addDataport.vue'
import authorizations from '../../components/authorizations.vue'
import blacklistGPN from '../../components/blacklistGPN.vue'
import downloads from '../../components/downloads.vue'
import manageUsers from '../../components/manageUsers.vue'
import searchEngine from '../../components/searchEngine.vue'
import dataportsWebsite from '../../components/dataportsWebsite.vue'
import signUp from '../../components/signUp.vue'
import signIn from '../../components/signIn.vue'
import myAccount from '../../components/myAccount.vue'
import setPassword from '../../components/setPassword.vue'
import requestNewPassword from '../../components/requestNewPassword.vue'
import editAuthorization from '../../components/editAuthorization.vue'

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        component: dataportsWebsite,
        name: 'Home',
        meta: {
            title: 'DataPorts',
        }
    },
    {
        path: '/dataportsWebsite',
        name: 'dataportsWebsite',
        component: dataportsWebsite,
        meta: {
            title: 'DataPorts',
        }
    },
    {
        path: '/signUp',
        name: 'signUp',
        component: signUp,
        meta: {
            title: 'DataPorts | Sign Up',
        }
    },
    {
        path: '/signIn',
        name: 'signIn',
        component: signIn,
        meta: {
            title: 'DataPorts | Sign In',
        }
    },
    {
        path: '/setPassword',
        name: 'setPassword',
        component: setPassword,
        meta: {
            title: 'DataPorts | Set Password',
        }
    },
    {
        path: '/requestNewPassword',
        name: 'requestNewPassword',
        component: requestNewPassword,
        meta: {
            title: 'DataPorts | Request Password',
        }
    },
    {
        path: '/myAccount',
        name: 'myAccount',
        component: myAccount,
        meta: {
            title: 'DataPorts | My Account',
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: dashBoard,

        meta: {
            title: 'DataPorts | Dashboard',
        }
    },
    {
        path: '/myDataports',
        name: 'myDataports',
        component: myDataports,

        meta: {
            title: 'DataPorts | My DataPorts',
        }
    },
    {
        path: '/editMyDataport/:id',
        props: true,
        name: 'editMyDataport',
        component: editMyDataport,
        meta: {
            title: 'DataPorts | Edit DataPorts',
        }
    },
    {
        path: '/organisation',
        name: 'organisation',
        component: organisation,
        meta: {
            title: 'DataPorts | Organisation',
        }
    },
    {
        path: '/addDataport',
        name: 'addDataport',
        component: addDataport,
        meta: {
            title: 'DataPorts | Add DataPort',
        }
    },
    {
        path: '/authorizations',
        name: 'authorizations',
        component: authorizations,
        meta: {
            title: 'DataPorts | Authorizations',
        }
    },
    {
        path: '/editAuthorization/:id',
        props: true,
        name: 'editAuthorization',
        component: editAuthorization,
        meta: {
            title: 'DataPorts | Edit Authorization',
        }
    },
    {
        path: '/blacklistGPN',
        name: 'blacklistGPN',
        component: blacklistGPN,
        meta: {
            title: 'DataPorts | Blacklist',
        }
    },
    {
        path: '/downloads',
        name: 'downloads',
        component: downloads,
        meta: {
            title: 'DataPorts | Downloads',
        }
    },
    {
        path: '/searchEngine',
        name: 'searchEngine',
        component: searchEngine,
        meta: {
            title: 'DataPorts | Search Engine',
        }
    },
    {
        path: '/manageUsers',
        name: 'manageUsers',
        component: manageUsers,
        meta: {
            title: 'DataPorts | Manage Users',
        }
    },
    {
        path: '*',
        redirect: {
            name: 'dataportsWebsite'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router