import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate"
import dataport from './storeModules/dataportModule.js'
import user from './storeModules/userModule.js'
import organisation from './storeModules/organisationModule.js'
import authorisation from './storeModules/authorisationModule.js'

Vue.use(Vuex)


export const store = new Vuex.Store({

    modules: {
        dataport,
        user,
        organisation,
        authorisation,
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        paths: ['user','organisation','dataport','authorisation']


    })]
})