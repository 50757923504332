<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="saveNewDataPort">
            <div>
                <div class="row" v-if="userProfile.admin">
                    <form class="col-sm-12 col-md-6" method="post">
                        <div v-if="errors.length">
                            <div class="alert alert-danger" role="alert">
                                <ul class="list-unstyled">
                                    <li
                                        v-for="(error, index) in errors"
                                        :key="index"
                                    >
                                        {{ error }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <router-link to="/myDataports">
                            <a href="#">
                                <i class="fas fa-chevron-left"></i>&nbsp;Back
                            </a>
                        </router-link>
                        <button
                            type="button"
                            class="btn btn-success btn-sm float-right"
                            @click="checkForm"
                        >
                            <i class="fas fa-save"></i>&nbsp;Save
                        </button>
                        <div class="content-header">New DataPort</div>
                        <div class="form-group">
                            <label for="dataportAddress">Address</label>
                            <input
                                type="text"
                                class="form-control"
                                id="dataportAddress"
                                v-model="newDataport.address"
                                readonly
                            />
                        </div>
                        <div class="form-group">
                            <label for="secretKey">Secret Key</label>
                            <input
                                type="text"
                                class="form-control"
                                id="secretKey"
                                v-model="newDataport.secretKey"
                                readonly
                            />
                        </div>

                        <ValidationProvider
                            name="Public Reference"
                            rules="required|alpha_spaces"
                            v-slot="v"
                        >
                            <div class="form-group">
                                <label for="pubRef">Public Reference</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="pubRef"
                                    placeholder="Enter a public reference"
                                    v-model="reference"
                                />
                            </div>
                            <span class="text-danger">
                                {{ v.errors[0] }}
                            </span>
                        </ValidationProvider>
                        <div class="form-group">
                            <label for="owner">Owner</label>

                            <input
                                class="form-control"
                                id="owner"
                                name="selectOwner"
                                v-model="organisationsProfile.organisationName"
                                readonly
                                value="organisationsProfile.organisationName"
                            />
                            <!-- <span class="required-asterisk">*</span>
                            <select
                                class="form-control"
                                id="owner"
                                name="selectOwner"
                                v-model="placeholderOwner"
                                required
                            >
                                <option
                            v-for="(option, index) in this.owners"
                            :key="index"
                            :value="option.id"
                        >
                            {{ option.value }}
                        </option>
                            </select> -->
                        </div>
                        <ValidationProvider
                            name="Public Reference"
                            rules="required|alpha_spaces"
                            v-slot="v"
                        >
                            <div class="form-group">
                                <label for="intRef">Internal Reference</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="intRef"
                                    placeholder="Enter a internal reference"
                                    v-model="internalReference"
                                />
                            </div>
                            <span class="text-danger">
                                {{ v.errors[0] }}
                            </span>
                        </ValidationProvider>
                        <label for>Listing type</label>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="privateRadio"
                                value="private"
                                checked
                                @change="radio = $event.target.value"
                            />
                            <label class="form-check-label" for="privateRadio"
                                >Private: DataPort address cannot be
                                discovered</label
                            >
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="exampleRadios"
                                id="publicRadio"
                                value="Public"
                                @change="radio = $event.target.value"
                            />
                            <label class="form-check-label" for="publicRadio"
                                >Public: DataPort address can be found by other
                                DataPorts</label
                            >
                        </div>
                    </form>

                    <div class="offset-md-6"></div>
                      advanced settings
           <form class="col-sm-12 col-md-6 mt-2">
        <hr />
        <div class="content-header">DataDock Settings</div>
        <div class="row">
          <div class="col-md-2 boldText">Product</div>
          <div class="col-md-5">
            DataContainer
            <div class="form-group">
              <label for="product-send1">
                <i class="far fa-paper-plane"></i>&nbsp;Send
              </label>
              <select class="form-control" id="product-send1" name="productSend1">
                <option value="no" selected>No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <div class="form-group">
              <label for="product-receive1">
                <i class="fas fa-inbox"></i>&nbsp;Receive
              </label>
              <select class="form-control" id="product-receive1" name="productReceive1">
                <option value="no" selected>No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
          </div>
          <div class="col-md-5">
            DataQuery
            <div class="form-group">
              <label for="product-send2">
                <i class="far fa-paper-plane"></i>&nbsp;Send
              </label>
              <select class="form-control" id="product-send2" name="productSend2">
                <option value="no" selected>No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <div class="form-group">
              <label for="product-receive2">
                <i class="fas fa-inbox"></i>&nbsp;Receive
              </label>
              <select class="form-control" id="product-receive2" name="productReceive2">
                <option value="no" selected>No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-2 boldText">Demand</div>
          <div class="col-md-5">
            DataContainer
            <div class="form-group">
              <label for="demand-send1">
                <i class="far fa-paper-plane"></i>&nbsp;Send
              </label>
              <select class="form-control" id="demand-send1" name="demandSend1">
                <option value="no" selected>No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <div class="form-group">
              <label for="demand-receive1">
                <i class="fas fa-inbox"></i>&nbsp;Receive
              </label>
              <select class="form-control" id="demand-receive1" name="demandReceive1">
                <option value="no" selected>No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
          </div>
          <div class="col-md-5">
            DataQuery
            <div class="form-group">
              <label for="demand-send2">
                <i class="far fa-paper-plane"></i>&nbsp;Send
              </label>
              <select class="form-control" id="demand-send2" name="demandSend2">
                <option value="no" selected>No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <div class="form-group">
              <label for="demand-receive2">
                <i class="fas fa-inbox"></i>&nbsp;Receive
              </label>
              <select class="form-control" id="demand-receive2" name="demandReceive2">
                <option value="no" selected>No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-2 boldText">Status</div>
          <div class="col-md-5">
            <div class="form-group">
              <select class="form-control" id="status-selected" name="statusSelected">
                <option value="disabled" selected>Disabled</option>
                <option value="enabled">Enabled</option>
              </select>
            </div>
          </div>
        </div>
            </form>
                </div>
                <div v-else class="mt-4">
                    <div class="alert alert-primary" role="alert">
                        You're not authorized to add DataPorts
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, alpha_spaces } from "vee-validate/dist/rules";

extend("alpha_spaces", {
    ...alpha_spaces,
    message: "Only alphabetic characters and spaces are allowed",
});

extend("required", {
    ...required,
});
export default {
    name: "addDataport",
    // Refactor data
    data() {
        return {
            errors: [],
            placeholderOwner: "",
            radio: "Private",
            reference: "",
            internalReference: "",
            dataportDTO: {},
        };
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    methods: {
        checkForm(e) {
            this.errors = [];
            if (!this.placeholderOwner) {
                this.errors.push("Please select an owner.");
            } else {
                this.saveNewDataPort();
            }
            e.preventDefault();
        },
        saveNewDataPort() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    return;
                }
                this.dataportDTO.id = "";
                this.dataportDTO.secretKey = this.newDataport.secretKey;
                this.dataportDTO.name = this.placeholderOwner;
                this.dataportDTO.listing = this.radio;
                this.dataportDTO.address = this.newDataport.address;
                this.dataportDTO.reference = this.reference;
                this.dataportDTO.internalReference = this.internalReference;
                this.dataportDTO.status = 2;
                const today = new Date();
                const date =
                    today.getFullYear() +
                    "-" +
                    (today.getMonth() + 1) +
                    "-" +
                    today.getDate();

                const time =
                    today.getHours() +
                    ":" +
                    today.getMinutes() +
                    ":" +
                    today.getSeconds();

                const dateTime = date + " " + time;
                this.dataportDTO.created = dateTime;
                this.$store
                    .dispatch("dataport/addDataport", this.dataportDTO)
                    .then(
                        () => this.$router.push("/myDataports"),
                        this.$router.go()
                    );
            });
        },
        async startup() {
            await this.$store.dispatch("dataport/newDataport");
        },
        pageReload() {
            this.$router.go(this.$router.currentRoute);
        },
    },
    mounted() {
        this.startup();
    },
    computed: {
        ...mapGetters({
            newDataport: "dataport/getNewDataport",
            userProfile: "user/getUserProfile",
            organisationsProfile: "organisation/getOrganisationProfile",
        }),
    },
    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>

<style scoped>
.alert ul {
    margin-bottom: 0;
}
.required-asterisk {
    color: red;
}
</style>