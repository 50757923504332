<template>
    <div>
        <div class="row justify-content-center mt-5">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <router-link to="/dataportsWebsite">
                    <a> <i class="fas fa-chevron-left"></i>&nbsp;Back </a>
                </router-link>
                <div class="card mt-3">
                    <div class="card-body">
                        <form>
                            <div class="signup-header">
                                <span class="dp-logo">
                                    <img src="../assets/dplogo.png" />
                                </span>
                                Welcome back
                            </div>
                            <div
                                v-if="this.loginFailed"
                                class="alert alert-warning"
                                role="alert"
                            >
                                Your login credentials are incorrect
                            </div>
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    placeholder="Your email address"
                                    autocomplete="on"
                                    v-model="signInEmail"
                                    required
                                />
                                <!-- <div
                                    class="text-danger"
                                    v-if="!checkEmail && signInEmail.length > 4"
                                >
                                    Invalid email
                                </div> -->
                            </div>
                            <div class="form-group">
                                <label for="password">Password</label>
                                <input
                                    type="password"
                                    class="form-control"
                                    id="password"
                                    placeholder="Password"
                                    autocomplete="on"
                                    v-model="signInPassword"
                                    required
                                />
                            </div>
                            <router-link to="/requestNewPassword">
                                <a class="text-link">Forgot your password?</a>
                            </router-link>
                            <button
                                v-if="!fieldsFilled"
                                type="button"
                                class="btn btn-primary float-right"
                                disabled
                            >
                                Sign in
                            </button>
                            <button
                                v-else
                                type="button"
                                class="btn btn-primary float-right"
                                @click="logIn()"
                            >
                                Sign in
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "signIn",
    data() {
        return {
            signInEmail: "",
            signInPassword: "",
        };
    },
    methods: {
        startUp(){
            this.$store.dispatch("user/resetLoginFailed");
            this.$store.dispatch("user/resetLoading");
        },
        logIn() {
            this.$store.dispatch("user/toggleLoadingMode");
            var credentials = {};
            credentials.email = this.signInEmail;
            credentials.password = this.signInPassword;
            this.$store
                .dispatch("user/asyncLogin", credentials)
                .then((response) => {
                    if (response.data === true) {
                        this.loadUserPorfile(credentials);
                    } else {
                        this.$store.dispatch("user/toggleLoadingMode");
                        this.$store.dispatch("user/loginFailed");
                    }
                });
        },
        loadUserPorfile(credentials) {
            var organisationDTO = {};
            this.$store
                .dispatch("user/loadUserProfile", credentials)
                .then(() => {
                    return this.$store.dispatch(
                        "user/loadUserPrivilege",
                        this.userProfile
                    );
                })
                .then(() => {
                    organisationDTO.id = this.userProfile.organisationId;
                    return this.$store.dispatch(
                        "organisation/loadOrganisationProfile",
                        organisationDTO
                    );
                })
                .then(() => {
                    this.$store.dispatch("user/toggleLoadingMode");
                })
                .then(() => this.$router.push("/dashboard"));
        },
    },
    mounted(){
        this.startUp();
    },
    computed: {
        ...mapGetters({
            loginFailed: "user/getLoginFailed",
            userProfile: "user/getUserProfile",
            organisationProfile: "organisation/getOrganisationProfile",
        }),
        fieldsFilled() {
            //check email validity: needs any letters or numbers + '@' , text behind '@' + 2-4 characters after the '.'
            const checkValidEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            return (
                checkValidEmail.test(this.signInEmail) &&
                this.signInPassword.length > 7
            );
        },
        // checkEmail() {
        //     const checkValidEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        //     return checkValidEmail.test(this.signInEmail);
        // },
    },
};
</script>

<style scoped>
.dp-logo img {
    width: 36px;
    height: auto;
    float: right;
}
</style>