import api from '../apis/api.js';
//import { use } from 'vue/types/umd';

const user = {
    namespaced: true,
    state: {

        loginFailed: false,
        isLoading: false,

        userProfile: {
            id: "",
            contactName: "",
            contactEmail: "",
            contactPhoneNumber: "",
            organisationId: ""

        },
        organisation: {
            organisationId: "",
            organisationName: "",
            organisationGPN: "",
            countryHQ: "",
            VATId: "",
        },
        profilePicture: {
            userId: '',
            image: ''
        },
        userPrivilege: [],
        selectedUserPrivileges: [{
            addBrand: false,
            addDataPort: false,
            addLocation: false,
            addOrganisation: false,
            addUser: false,
            archiveDataPort: false,
            authorizeDataPort: false,
            deleteUser: false,
            editDataPort: false,
            editUser: false,
            editUserRight: false,


        }],
        organisationUsers: [],
    },

    // getters
    getters: {
        getLoginFailed(state) {
            return state.loginFailed;
        },
        getIsloading(state) {
            return state.isLoading;
        },
        getUserProfile(state) {
            return state.userProfile;
        },
        getUserCanEdit(state) {
            return state.admin;
        },
        getProfilePicture(state) {
            return state.profilePicture;
        },
        getUserPrivilege(state) {
            return state.userPrivilege[0];
        },
        getSelectedUserPrivilege(state) {
            return state.selectedUserPrivileges[0];
        },
        getOrganisationUserList(state) {
            return state.organisationUsers;
        },
    },

    // actions
    actions: {

        async asyncLogin(_state, DTO) {

            return api.methods.sentPOSTRequestWithBody("/login", DTO);
        },
        async loginFailed(state) {
            await state.commit("loginFailed")
        },
        async resetLoginFailed(state){
            await state.commit("resetLoginFailed");
        },
        async toggleLoadingMode(state) {
            await state.commit("updateloadingMode");
        },
        async resetLoading(state){
            await state.commit("resetLoading");
        },
        async loadUserProfile(state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/getprofile", DTO).then(response => {
                state.commit("updateUserProfile", response.data);
            })
        },
        async editprofile(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/editprofile", DTO)
        },
        async uploadProfilePicture(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/uploadprofilepicture", DTO)
        },
        async registerUser(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/addusertoorganisation", DTO)
        },
        async loadUserPrivilege(state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/getuserprivileges", DTO).then(response => {
                state.commit("updateUserPrivilege", response.data)
            })
        },
        async loadSelectedUserPrivilege(state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/getuserprivileges", DTO).then(response => {
                state.commit("updateSelectedUserPrivileges", response.data)
            })
        },
        async editUserPrivilege(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/updateuserprivileges", DTO)
        },
        async loadAllOrganisationUsers(state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/LoadAllUsersFromOrganisation", DTO).then(response => {
                state.commit("updateUserList", response.data);
            })
        }

    },

    // mutations
    mutations: {
        updateUserDTO(state, DTO) {
            state.userDTO = DTO;
        },
        loginFailed(state) {
            state.loginFailed = true;
        },
        resetLoginFailed(state){
            state.loginFailed = false;
        },
        updateloadingMode(state) {
            state.isLoading = !state.isLoading;
        },
        resetLoading(state){
            state.isLoading = false;
        },
        updateUserProfile(state, DTO) {
            state.userProfile = DTO;
        },
        updateUserPrivilege(state, data) {
            state.userPrivilege = data;
        },
        updateSelectedUserPrivileges(state, data) {
            state.selectedUserPrivileges = [];
            state.selectedUserPrivileges = data;
        },
        updateUserList(state, data) {
            state.organisationUsers = data;
            if (state.userPrivilege[0].addUser == true) {
                let createUser = {
                    admin: null,
                    id: null,
                    name: "Create User",
                    contactEmail: "",
                    contactPhoneNumber: "",
                    organisationId: state.userProfile.organisationId
                }
                state.organisationUsers.unshift(createUser)
            } else {
                return;
            }

        },
    }
}

export default user