<template>
    <div>
        <div class="self-service-portal-header mt-5">
            <div class="row pt-sm-4 pt-lg-0">
                <div class="col-sm-8">
                    <h1>Self Service Portal</h1>
                </div>
                <div class="col-sm-4 user-info">
                    <profileThumbnail></profileThumbnail>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import profileThumbnail from "./profileThumbnail";

export default {
    name: "portalHeader",
    components: {
        profileThumbnail,
    },
};
</script>

<style scoped>
.self-service-portal-header {
    margin-bottom: 100px;
}

.self-service-portal-header h1 {
    font-weight: 700;
    color: #1d344d;
}
</style>