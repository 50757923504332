<template>
    <div>
        <div v-if="userProfile.admin">
            <div class="row">
                <div class="col-md-12">
                    <label class="profile-header mb-2" for="userSelect"
                        >Select a user</label
                    >
                    <div class="row">
                        <div class="col-md-6">
                            <div v-if="errors.length">
                                <div class="alert alert-danger" role="alert">
                                    <ul class="list-unstyled">
                                        <li
                                            v-for="(error, index) in errors"
                                            :key="index"
                                        >
                                            {{ error }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <select
                                id="user"
                                name="user"
                                class="form-control form-control-sm"
                                aria-describedby="userSelect"
                                @change="onChange($event)"
                                required
                            >
                                <option
                                    v-for="(user, index) in userList"
                                    :key="index"
                                    :value="user.contactName"
                                >
                                    {{ user.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <!-- temp comment delete -->
                            <!-- <button
                                type="button"
                                class="btn btn-danger btn-sm float-right mr-3"
                                :disabled="user.contactName == 0"
                                @click="deleteUser"
                            >
                                <i class="fas fa-user-alt-slash"></i
                                >&nbsp;Delete user account
                            </button> -->
                            <button
                                v-if="selectedCreateUserButtonToggle"
                                type="button"
                                class="btn btn-primary btn-sm float-right"
                                @click="createUser"
                                :disabled="
                                    !user.contactName ||
                                    !user.contactEmail ||
                                    !user.password
                                "
                            >
                                <i class="fas fa-user-plus"></i>&nbsp;Create
                                user account
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Personal information -->
                <div class="col-md-6 mt-4">
                    <div class="profile-header mb-2">
                        Personal information
                        <button
                            v-if="user.id !== null && EditUserInformation"
                            type="submit"
                            class="btn btn-success btn-sm float-right"
                            @click="editUser"
                        >
                            <i class="fas fa-save"></i>&nbsp;User information
                        </button>
                    </div>
                    <ValidationObserver ref="form">
                        <form @submit.prevent="editUser">
                            <ValidationProvider
                                name="Full name"
                                rules="required|alpha_spaces"
                                v-slot="v"
                            >
                                <div class="form-group">
                                    <label for="full-Name"
                                        >Full name<span class="text-danger"
                                            >*</span
                                        ></label
                                    >
                                    <small id="fullName" class="text-muted ml-2"
                                        >First & last name</small
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        id="full-Name"
                                        placeholder="Full name"
                                        aria-describedby="fullName"
                                        autocomplete="name"
                                        v-model="user.contactName"
                                        :disabled="EditUserInformation == false"
                                    />
                                    <span class="text-danger">
                                        {{ v.errors[0] }}
                                    </span>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider
                                name="Email"
                                rules="required|email"
                                v-slot="v"
                            >
                                <div class="form-group">
                                    <label for="contactEmail"
                                        >Email<span class="text-danger"
                                            >*</span
                                        ></label
                                    >
                                    <input
                                        type="email"
                                        class="form-control form-control-sm"
                                        id="contactEmail"
                                        placeholder="Email"
                                        aria-describedby="contactEmail"
                                        autocomplete="email"
                                        v-model="user.contactEmail"
                                        :disabled="EditUserInformation == false"
                                    />
                                    <span class="text-danger">
                                        {{ v.errors[0] }}
                                    </span>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider
                                name="Password"
                                rules="required"
                                v-slot="v"
                                v-if="user.id === null"
                            >
                                <div class="form-group">
                                    <label for="contactEmail"
                                        >Password<span class="text-danger"
                                            >*</span
                                        ></label
                                    >
                                    <div class="input-group input-group-sm">
                                        <input
                                            type="password"
                                            class="form-control remove-input-border"
                                            id="password"
                                            placeholder="Password"
                                            aria-describedby="password"
                                            autocomplete="current-password"
                                            v-model="user.password"
                                            ref="passwordInput"
                                        />
                                        <span
                                            @click="togglePassword"
                                            class="input-group-append input-group-text remove-addon-styling"
                                        >
                                            <div
                                                v-show="!showPassword"
                                                class="password-toggle"
                                            >
                                                <i class="fas fa-eye-slash"></i>
                                            </div>
                                            <div
                                                v-show="showPassword"
                                                class="password-toggle"
                                            >
                                                <i
                                                    class="fas fa-eye text-primary"
                                                ></i>
                                            </div>
                                        </span>
                                    </div>
                                    <span class="text-danger">
                                        {{ v.errors[0] }}
                                    </span>
                                </div>
                            </ValidationProvider>
                            <div class="form-group">
                                <label for="contactPhone">Phone number</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <vue-country-code
                                            :preferredCountries="[
                                                'us',
                                                'cn',
                                                'gb',
                                            ]"
                                            enabledCountryCode
                                        ></vue-country-code>
                                    </div>
                                    <input
                                        type="tel-local"
                                        class="form-control"
                                        id="contactPhone"
                                        placeholder="Phone number"
                                        v-model="user.contactPhoneNumber"
                                        :disabled="EditUserInformation == false"
                                    />
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>

                    <!-- <div class="row">
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <img
                                        src="https://widgetwhats.com/app/uploads/2019/11/free-profile-photo-whatsapp-4.png"
                                        alt="user-profile-picture"
                                    />
                                    <div class="image-button-container mt-3">
                                        <button
                                            type="button"
                                            class="btn btn-danger btn-sm"
                                        >
                                            <i class="fas fa-trash-alt"></i>
                                            &nbsp;Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <!-- Rights & user privilege -->
                <div class="col-md-6 mt-4">
                    <div class="profile-header mb-2">
                        Rights and privileges
                        <button
                            v-if="user.id !== null && EditUserPrivileges"
                            type="button"
                            class="btn btn-success btn-sm float-right"
                            @click="editUserPrivilege"
                        >
                            <i class="fas fa-save"></i>&nbsp;Rights & privileges
                        </button>
                    </div>
                    <div class="form-group">
                        <label for="myDataportRights">My DataPorts</label>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="addDataPorts"
                                disabled
                                v-model="selectedUserPrivilege.addDataPort"
                                true-value="true"
                                false-value="false"
                            />
                            <label class="form-check-label" for="addDataPorts"
                                >Add DataPorts</label
                            >
                        </div>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="editDataports"
                                :disabled="
                                    user.admin == 1 ||
                                    EditUserPrivileges == false
                                "
                                v-model="selectedUserPrivilege.editDataPort"
                                true-value="true"
                                false-value="false"
                            />
                            <label class="form-check-label" for="editDataports"
                                >Edit DataPorts</label
                            >
                        </div>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="archiveDataports"
                                :disabled="
                                    user.admin == 1 ||
                                    EditUserPrivileges == false
                                "
                                v-model="selectedUserPrivilege.archiveDataPort"
                                true-value="true"
                                false-value="false"
                            />
                            <label
                                class="form-check-label"
                                for="archiveDataports"
                                >Archive DataPorts</label
                            >
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="authorizationRights">Authorizations</label>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="authorizeDataports"
                                disabled
                                v-model="
                                    selectedUserPrivilege.authorizeDataPort
                                "
                                true-value="true"
                                false-value="false"
                            />
                            <label
                                class="form-check-label"
                                for="authorizeDataports"
                                >Authorize DataPorts</label
                            >
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="OrganisationRights">Organisation</label>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="addOrganisations"
                                disabled
                                v-model="selectedUserPrivilege.addOrganisation"
                                true-value="true"
                                false-value="false"
                            />
                            <label
                                class="form-check-label"
                                for="addOrganisations"
                                >Add Organisations</label
                            >
                        </div>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="addLocations"
                                disabled
                                v-model="selectedUserPrivilege.addLocation"
                                true-value="true"
                                false-value="false"
                            />
                            <label class="form-check-label" for="addLocations"
                                >Add Locations</label
                            >
                        </div>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="addBrands"
                                disabled
                                v-model="selectedUserPrivilege.addBrand"
                                true-value="true"
                                false-value="false"
                            />
                            <label class="form-check-label" for="addBrands"
                                >Add Brands</label
                            >
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="organisationRights">Manage users</label>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="manageUsers"
                                :disabled="
                                    user.admin == 1 ||
                                    EditUserPrivileges == false
                                "
                                v-model="selectedUserPrivilege.addUser"
                                true-value="true"
                                false-value="false"
                            />
                            <label class="form-check-label" for="manageUsers"
                                >Add users</label
                            >
                        </div>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="manageUsers"
                                :disabled="
                                    user.admin == 1 ||
                                    EditUserPrivileges == false
                                "
                                v-model="selectedUserPrivilege.editUser"
                                true-value="true"
                                false-value="false"
                            />
                            <label class="form-check-label" for="manageUsers"
                                >Edit personal information of other users</label
                            >
                        </div>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="manageUsers"
                                :disabled="
                                    user.admin == 1 ||
                                    EditUserPrivileges == false
                                "
                                v-model="selectedUserPrivilege.editUserRight"
                                true-value="true"
                                false-value="false"
                            />
                            <label class="form-check-label" for="manageUsers"
                                >Edit privileges of other users</label
                            >
                        </div>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="deleteUsers"
                                disabled
                                v-model="selectedUserPrivilege.deleteUser"
                                true-value="true"
                                false-value="false"
                            />
                            <label class="form-check-label" for="deleteUsers"
                                >Delete user accounts</label
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="mt-4">
            <div class="alert alert-primary" role="alert">
                You're not authorized to edit users.
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, email, alpha_spaces } from "vee-validate/dist/rules";

// Override message
extend("email", {
    ...email,
    message: "This is not a valid email address",
});

extend("alpha_spaces", {
    ...alpha_spaces,
    message: "Only alphabetic characters and spaces are allowed",
});

extend("required", {
    ...required,
});

export default {
    name: "manageUsers",
    data() {
        return {
            errors: false,
            showPassword: false,
            user: {
                admin: null,
                id: null,
                contactName: "",
                contactEmail: "",
                contactPhoneNumber: "",
                password: "",
                organisationId: "",
            },
        };
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    methods: {
        deleteUser() {
            this.$store.dispatch("user/deleteUser", this.user);
        },
        editUser() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    return;
                }
                this.$store
                    .dispatch("user/editprofile", this.user)
                    .finally(this.$router.go());
            });
        },
        createUser() {
            this.user.organisationId = this.userProfile.organisationId;
            this.$store
                .dispatch("user/registerUser", this.user)
                .then(() =>
                    this.$store
                        .dispatch(
                            "user/editUserPrivilege",
                            this.selectedUserPrivilege
                        )
                        .finally(this.$router.go())
                );
        },
        editUserPrivilege() {
            this.$store
                .dispatch("user/editUserPrivilege", this.selectedUserPrivilege)
                .finally(this.$router.go());
        },
        changepassword() {
            this.$store.dispatch("user/changepassword", this.user);
        },
        loadSelectedUserPrivilege() {
            this.$store.dispatch("user/loadSelectedUserPrivilege", this.user);
        },
        onChange(event) {
            if (event.target.value == "Create User") {
                this.user.id = null;
                this.user.name = "";
                this.user.contactEmail = "";
                this.user.contactPhoneNumber = "";
                this.user.password = "";
                this.user.organisationId = "";
                this.resetPrivilegeBoxes();
            } else {
                let index = this.userList.findIndex(
                    (x) => x.contactName === event.target.value
                );
                let temp = JSON.parse(JSON.stringify(this.userList[index]));
                this.user = temp;
                this.loadSelectedUserPrivilege();
            }
        },

        resetPrivilegeBoxes() {
            this.selectedUserPrivilege.addDataPort = false;
            this.selectedUserPrivilege.editDataPort = false;
            this.selectedUserPrivilege.archiveDataPort = false;
            this.selectedUserPrivilege.authorizeDataPort = false;
            this.selectedUserPrivilege.addOrganisation = false;
            this.selectedUserPrivilege.addLocation = false;
            this.selectedUserPrivilege.addBrand = false;
            this.selectedUserPrivilege.editUserRight = false;
            this.selectedUserPrivilege.addPersonal = false;
            this.selectedUserPrivilege.addUser = false;
            this.selectedUserPrivilege.editUser = false;
            this.selectedUserPrivilege.deleteUser = false;
        },
        togglePassword() {
            var x = this.$refs["passwordInput"];
            if (x.type === "password") {
                x.type = "text";
                this.showPassword = true;
            } else {
                x.type = "password";
                this.showPassword = false;
            }
        },
    },
    mounted() {
        var userprofileDTO = {};
        userprofileDTO.id = this.organisationProfile.id;
        this.$store
            .dispatch("user/toggleLoadingMode")
            .then(() =>
                this.$store
                    .dispatch("user/loadAllOrganisationUsers", this.userProfile)
                    .then(() => this.$store.dispatch("user/toggleLoadingMode"))
            );

        this.resetPrivilegeBoxes();
    },
    computed: {
        ...mapGetters({
            userProfile: "user/getUserProfile",
            userList: "user/getOrganisationUserList",
            userprivilege: "user/getUserPrivilege",
            selectedUserPrivilege: "user/getSelectedUserPrivilege",
            organisationProfile: "organisation/getOrganisationProfile",
        }),
        selectedCreateUserButtonToggle() {
            if ((this.userprivilege.addUser == true) & (this.user.id == null)) {
                return true;
            } else {
                return false;
            }
        },
        EditUserPrivileges() {
            if (this.userprivilege.editUserRight == true) {
                return true;
            } else {
                return false;
            }
        },
        EditUserInformation() {
            if (this.userprivilege.editUser == true) {
                return true;
            } else if (
                this.userprivilege.addUser == true &&
                this.user.id == null
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>
<style scoped>
.input-group-text {
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

::v-deep .country-code {
    margin-left: 5px;
}
.vue-country-select {
    background-color: #e9ecef;
    border-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
::v-deep .vue-country-select .dropdown-list {
    z-index: 99;
}

.card img {
    object-fit: cover;
    width: 100%;
    height: 195px;
    border: 2px solid #1d344d;
    border-radius: 50%;
}
.image-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.remove-addon-styling {
    background-color: #fff;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.remove-input-border {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
</style>