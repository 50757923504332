<template>
    <div>
        <div class="row" v-if="downloads.length > 0">
            <div class="dataports-table col-lg-12 col-xl-6">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Toolkits</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(download, index) in downloads" :key="index">
                            <td>{{ download.name }}</td>
                            <td class="text-right">
                                <button
                                    type="button"
                                    class="btn btn-link"
                                >
                                    <a :href="download.url" target="_blank">
                                        <i
                                            class="fas fa-cloud-download-alt"
                                        ></i>
                                    </a>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else class="no-dataports-added">
            <div class="alert alert-primary" role="alert">
                No available downloads
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "downloads",
    data() {
        return {
            downloads: [
                {
                    name: "NuGet Package",
                    version: "1.1",
                    url:
                        "https://github.com/Azlon-io/DataPorts",
                },
                {
                    name: "Java Development Toolkit",
                    version: "2.0",
                    url:
                        "https://github.com/Azlon-io/DataPorts",
                },
            ],
        };
    },
    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>

<style scoped>
</style>