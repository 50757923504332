<template>
    <div>
        <div class="row justify-content-center mt-5">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <router-link to="/signIn">
                    <a> <i class="fas fa-chevron-left"></i>&nbsp;Back </a>
                </router-link>
                <div class="card mt-3">
                    <div class="card-body">
                        <form>
                            <div class="signup-header">
                                Forgot your password?
                            </div>
                            <div
                                class="alert alert-primary"
                                role="alert"
                                v-if="requestPasswordMessage"
                            >
                                We'll check your email address and send you a
                                link to reset your password.
                                <br />You can close this page.
                            </div>
                            <div class="form-group">
                                <label for="email"
                                    >Enter the email address associated with
                                    your DataPorts account</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    id="email"
                                    placeholder="Your email address"
                                    autocomplete="on"
                                    v-model="signInEmail"
                                    required
                                    :readonly="requestPasswordMessage"
                                />
                                <div
                                    class="text-danger"
                                    v-if="!checkEmail && signInEmail.length > 4"
                                >
                                    Invalid email
                                </div>
                            </div>
                            <button
                                v-if="!checkEmail"
                                type="button"
                                class="btn btn-primary float-right"
                                disabled
                            >
                                Request password
                            </button>
                            <button
                                v-else
                                type="button"
                                class="btn btn-primary float-right"
                                @click="requestNewPassword"
                                :disabled="requestPasswordMessage"
                            >
                                Request password
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "requestNewPassword",
    data() {
        return {
            signInEmail: "",
            requestPasswordMessage: false,
        };
    },
    methods: {
        requestNewPassword() {
            this.requestPasswordMessage = true;
            //vuex logic to reset a password
        },
    },
    computed: {
        checkEmail() {
            const checkValidEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            return checkValidEmail.test(this.signInEmail);
        },
    },
};
</script>
<style scoped>
</style>