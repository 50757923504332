<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div
                    class="btn-group btn-group-sm"
                    role="group"
                    aria-label="Btn filter group"
                    v-if="this.sortList"
                >
                    <button
                        type="button"
                        class="btn btn-light"
                        :class="{
                            active: authorizedFilterKey == 'showPendingList',
                        }"
                        @click="authorizedFilterKey = 'showPendingList'"
                    >
                        Pending&nbsp;
                        <span class="badge badge-light">{{
                            showPendingList.length
                        }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-light"
                        :class="{
                            active: authorizedFilterKey == 'showAuthorizedList',
                        }"
                        @click="authorizedFilterKey = 'showAuthorizedList'"
                    >
                        Authorized&nbsp;
                        <span class="badge badge-light">{{
                            showAuthorizedList.length
                        }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-light"
                        :class="{
                            active: authorizedFilterKey == 'showDeclinedList',
                        }"
                        @click="authorizedFilterKey = 'showDeclinedList'"
                    >
                        Declined/Revoked&nbsp;
                        <span class="badge badge-light">{{
                            showDeclinedList.length
                        }}</span>
                    </button>
                </div>
                <div
                    class="input-group input-group-sm float-right search-input ml-1"
                >
                    <input
                        type="text"
                        class="form-control border-right-0"
                        placeholder="Search DataPort"
                        v-model="searchDataports"
                    />
                    <div class="input-group-append">
                        <span class="input-group-text remove-addon-styling">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                </div>
                <router-link to="/blacklistGPN">
                    <button
                        type="button"
                        class="btn btn-sm btn-primary float-right"
                    >
                        GPN Blacklist
                    </button>
                </router-link>
            </div>
        </div>
        <div
            class="dataports-table table-responsive mt-4"
            v-if="this.authorizedDataportList.length > 0"
        >
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            class="th-clickable"
                            @click="sort('created')"
                        >
                            Date
                            <div
                                v-show="this.currentSortDir == 'asc'"
                                class="ml-1 sort-order-indicator"
                            >
                                <i class="fas text-muted fa-caret-up"></i>
                            </div>
                            <div
                                v-show="this.currentSortDir == 'desc'"
                                class="ml-1 sort-order-indicator"
                            >
                                <i class="fas text-muted fa-caret-down"></i>
                            </div>
                        </th>
                        <th
                            scope="col"
                            class="th-clickable"
                            @click="sort('name')"
                        >
                            Requestor&nbsp;
                            <div
                                v-show="this.currentSortDir == 'asc'"
                                class="ml-1 sort-order-indicator"
                            >
                                <i class="fas text-muted fa-caret-up"></i>
                            </div>
                            <div
                                v-show="this.currentSortDir == 'desc'"
                                class="ml-1 sort-order-indicator"
                            >
                                <i class="fas text-muted fa-caret-down"></i>
                            </div>
                        </th>
                        <th scope="col">Requestor DataPort</th>
                        <th
                            scope="col"
                            class="th-clickable"
                            @click="sort('target')"
                        >
                            Target
                            <div
                                v-show="this.currentSortDir == 'asc'"
                                class="ml-1 sort-order-indicator"
                            >
                                <i class="fas text-muted fa-caret-up"></i>
                            </div>
                            <div
                                v-show="this.currentSortDir == 'desc'"
                                class="ml-1 sort-order-indicator"
                            >
                                <i class="fas text-muted fa-caret-down"></i>
                            </div>
                        </th>
                        <th scope="col">Target DataPort</th>
                        <!-- <th scope="col">Type</th>
                        <th scope="col"></th> -->
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(authorization, index) in sortList" :key="index">
                        <td>{{ formatDate(authorization.created) }}</td>
                        <td>{{ authorization.sourceDataPortName }}</td>
                        <td>{{ authorization.sourceDataPortId }}</td>
                        <td>{{ authorization.destinationDataPortName }}</td>
                        <td>{{ authorization.destinationDataPortId }}</td>

                        <!-- <td>Push & Demand</td> -->
                        <!-- <td>
                            <div v-if="authorization.Status === 0">
                                <span class="badge badge-warning">
                                    <i class="fas fa-hourglass-half"></i
                                    >&nbsp;Pending
                                </span>
                            </div>
                            <div v-else-if="authorization.Status === 1">
                                <span class="badge badge-success">
                                    <i class="fas fa-shield-alt"></i
                                    >&nbsp;Authorized
                                </span>
                            </div>
                            <div v-else-if="authorization.Status === 2">
                                <span class="badge badge-danger">
                                    <i class="fas fa-hand-paper"></i
                                    >&nbsp;Declined
                                </span>
                            </div>
                        </td> -->
                        <td>
                            <div
                                v-if="
                                    authorization.status === 0 &&
                                    authorization.owned === true
                                "
                            >
                                <button
                                    type="button"
                                    class="btn text-success"
                                    @click="
                                        acceptAuthorisationRequest(
                                            authorization.id
                                        )
                                    "
                                >
                                    <i class="fas fa-check"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn text-danger"
                                    @click="
                                        declineAuthorisationRequest(
                                            authorization.id
                                        )
                                    "
                                >
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                            <router-link
                                :to="{
                                    path:
                                        '/editAuthorization/' +
                                        authorization.id,
                                }"
                            >
                                <div
                                    v-if="
                                        authorization.status === 1
                                    "
                                >
                                    <div
                                        class="text-primary float-right"
                                        v-tooltip="{
                                            content:
                                                'Edit authorization status',
                                        }"
                                    >
                                        <i class="fas fa-pencil-alt"></i>
                                    </div>
                                </div>
                            </router-link>
                            <router-link
                                :to="{
                                    path:
                                        '/editAuthorization/' +
                                        authorization.id,
                                }"
                            >
                                <div
                                    v-if="
                                        authorization.status === 2 ||
                                        authorization.status === 3
                                    "
                                >
                                    <div
                                        class="text-primary float-right"
                                        v-tooltip="{
                                            content:
                                                'Edit authorization status',
                                        }"
                                    >
                                        <i class="fas fa-pencil-alt"></i>
                                    </div>
                                </div>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="no-dataports-added mt-4">
            <div class="alert alert-primary" role="alert">
                No authorizations found
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "authorizations",
    data() {
        return {
            authorizedFilterKey: "showPendingList",
            sortKey: "date",
            currentSort: "name",
            currentSortDir: "asc",
            organisationProfileDTO: {},
            AuthRequestDTO: {},
            searchDataports: "",
        };
    },
    methods: {
        sort(s) {
            //if s == current sort, reverse
            if (this.currentSortDir == "asc") {
                this.currentSortDir = "desc";
            } else {
                this.currentSortDir = "asc";
            }
            this.currentSort = s;
        },
        acceptAuthorisationRequest(data) {
            this.AuthRequestDTO.id = data;

            this.$store
                .dispatch(
                    "authorisation/acceptAuthorisationRequest",
                    this.AuthRequestDTO
                )
                .then(() => {
                    this.organisationProfileDTO.id = this.userProfile.organisationId;
                    return this.$store.dispatch(
                        "authorisation/loadAuthorisedDataports",
                        this.organisationProfileDTO
                    );
                });
        },
        declineAuthorisationRequest(data) {
            this.AuthRequestDTO.id = data;
            this.$store
                .dispatch(
                    "authorisation/declineAuthorisationRequest",
                    this.AuthRequestDTO
                )
                .then(() => {
                    this.organisationProfileDTO.id = this.userProfile.organisationId;
                    return this.$store.dispatch(
                        "authorisation/loadAuthorisedDataports",
                        this.organisationProfileDTO
                    );
                });
        },
        revokeAuthorisation(data) {
            this.AuthRequestDTO.id = data;
            this.$store
                .dispatch(
                    "authorisation/revokeAuthorisation",
                    this.AuthRequestDTO
                )
                .then(() => {
                    this.organisationProfileDTO.id = this.userProfile.organisationId;
                    return this.$store.dispatch(
                        "authorisation/loadAuthorisedDataports",
                        this.organisationProfileDTO
                    );
                });
        },
        removeAuthorisation(data) {
            this.AuthRequestDTO.id = data;
            this.$store
                .dispatch(
                    "authorisation/RemoveAuthorisation",
                    this.AuthRequestDTO
                )
                .then(() => {
                    this.organisationProfileDTO.id = this.userProfile.organisationId;
                    return this.$store.dispatch(
                        "authorisation/loadAuthorisedDataports",
                        this.organisationProfileDTO
                    );
                });
        },
        checkDataportOwner(targetDataPort) {
            if (
                this.dataports.find(
                    (auth) => auth.destinationDataPortId === targetDataPort
                ) !== null
            ) {
                return true;
            } else {
                return false;
            }
        },
        startup() {
            this.$store.dispatch("user/toggleLoadingMode");
            this.organisationProfileDTO.id = this.userProfile.organisationId;
            this.$store
                .dispatch("dataport/loadDataports", this.organisationProfileDTO)
                .then(() => {
                    var DTO = this.organisationProfileDTO;
                    var dataPorts = this.dataports;
                    return this.$store
                        .dispatch("authorisation/loadauthorisations", {
                            DTO,
                            dataPorts,
                        })
                        .then(() => {
                            return this.$store.dispatch(
                                "authorisation/loadAuthorisedStatusList"
                            );
                        })
                        .finally(
                            this.$store.dispatch("user/toggleLoadingMode")
                        );
                });
        },

        formatDate(x) {
            var date = new Date(Date.parse(x)).toLocaleDateString("en-GB");
            return (x = date);
        },
    },
    mounted() {
        this.startup();
    },
    computed: {
        ...mapGetters({
            authorizedDataportList: "authorisation/getAuthorizedDataports",
            dataports: "dataport/getDataports",
            userProfile: "user/getUserProfile",
        }),

        sortList() {
            return this[this.authorizedFilterKey]
                .slice()
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === "desc") modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort])
                        return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort])
                        return 1 * modifier;
                    return 0;
                })
                .filter((dataport) => {
                    return (
                        dataport.sourceDataPortId
                            .toLowerCase()
                            .match(this.searchDataports.toLowerCase()) ||
                        dataport.sourceDataPortName
                            .toLowerCase()
                            .match(this.searchDataports.toLowerCase()) ||
                        dataport.destinationDataPortId
                            .toLowerCase()
                            .match(this.searchDataports.toLowerCase()) ||
                        dataport.destinationDataPortName
                            .toLowerCase()
                            .match(this.searchDataports.toLowerCase())
                    );
                });
        },

        showPendingList() {
            return this.authorizedDataportList.filter((a) => a.status === 0);
        },

        showAuthorizedList() {
            return this.authorizedDataportList.filter((a) => a.status === 1);
        },

        showDeclinedList() {
            return this.authorizedDataportList.filter(
                (a) => a.status === 2 || a.status === 3
            );
        },
    },

    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>

<style scoped>
th,
td {
    min-width: 100px;
}

.th-clickable {
    cursor: pointer;
}

.sort-order-indicator {
    display: inline-block;
}
.search-input {
    width: 20%;
}

.remove-addon-styling {
    background-color: #fff;
}

@media only screen and (max-width: 1140px) {
    .search-input {
        width: 40%;
    }
}
</style>