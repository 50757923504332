<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-sm-12 col-md-12 col-lg-6">
                <div
                    class="dp-logo"
                    v-if="this.$route.path !== '/searchEngine'"
                >
                    <img
                        src="../assets/dplogo.png"
                        alt="dataports-logo"
                        width="72"
                        height="72"
                    />
                </div>
                <label for="inputQuery" class="sr-only">Query</label>
                <input
                    type="text"
                    id="inputQuery"
                    ref="inputQuery"
                    class="form-control dp-input mt-4 mb-4"
                    placeholder="Search DataPorts"
                    autofocus
                    required
                    v-model="searchQuery"
                    @keyup.enter="getDataportResults"
                />
                <div class="text-center">
                    <button
                        id="search"
                        class="btn btn-primary"
                        type="button"
                        @click="getDataportResults"
                        :disabled="!searchQuery"
                    >
                        Search
                    </button>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div v-show="loadingSearchResults" class="mt-4">
                    Searching DataPorts...
                </div>
                <div class="mt-4" v-if="searchResults.length">
                    <!-- append search results here -->
                    <div v-for="(result, index) in searchResults" :key="index">
                        <div class="title text-primary d-inline-block mr-2">
                            {{ result.name }}
                        </div>
                        <!-- <div class="badge badge-success d-inline-block">
                            <i class="fas fa-plug mr-1"></i>Connected
                            <span class="badge badge-light">{{
                                connectedDataPorts
                            }}</span>
                        </div> -->
                        <div
                            class="mb-4"
                            v-for="(service, serviceIndex) in result.services"
                            :key="serviceIndex"
                        >
                            <div v-if="service.enabled">
                                <div class="dpSearch-reference">
                                    Reference:&nbsp;{{ service.reference }}
                                </div>
                                <div class="text-success">
                                    DataPort:&nbsp;{{ service.dataport }}
                                </div>
                                <div>DataDock:&nbsp;{{ service.datadock }}</div>
                            </div>
                            <div
                                class="text-info"
                                v-if="!$store.state.user.userProfile.email"
                            >
                                Sign in to request DataPort access
                            </div>
                            <button
                                data-toggle="modal"
                                data-target="#connectDataPortsModal"
                                v-if="
                                    $store.state.user.userProfile.email &&
                                    canRequestAccess &&
                                    !requestedAccess
                                "
                                @click="requestDataportAccess(service.dataport)"
                                type="button"
                                class="btn btn-primary btn-sm"
                            >
                                Request access
                            </button>
                            <button
                                v-if="canRequestAccess && requestedAccess"
                                @click="requestDataportAccess(service.dataport)"
                                type="button"
                                class="btn btn-primary btn-sm"
                                disabled
                            >
                                Pending authorization
                            </button>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        tabindex="-1"
                        role="dialog"
                        id="connectDataPortsModal"
                    >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Request access</h5>
                                    <button
                                        type="button"
                                        class="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div
                                        v-show="authorisationStatus !== null"
                                        class="alert alert-warning"
                                        role="alert"
                                    >
                                        The selected DataPort is
                                        <span class="boldText">{{
                                            showAuthorisationStatus
                                        }}</span>
                                        with the target DataPort.
                                    </div>
                                    <div class="form-group">
                                        <label
                                            for="myDataPorts"
                                            class="col-form-label"
                                            >Connect my DataPort</label
                                        >
                                        <select
                                            class="form-control"
                                            id="myDataPorts"
                                            name="myDataPorts"
                                            @change="
                                                checkAuthorisationStatus($event)
                                            "
                                        >
                                            <option disabled selected>
                                                - Select one of your DataPorts -
                                            </option>

                                            <option
                                                v-for="(
                                                    dataPort, index
                                                ) in $store.state.dataport
                                                    .dataports"
                                                :key="index"
                                                :value="dataPort.id"
                                            >
                                                {{ dataPort.reference }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label
                                            for="connectTo"
                                            class="col-form-label"
                                            >To</label
                                        >
                                        <input
                                            readonly
                                            type="text"
                                            class="form-control"
                                            id="connectToThisDataPort"
                                            placeholder="Owner's DataPort"
                                            v-model="selectedTargetDataPort"
                                        />
                                    </div>
                                </div>
                                <!-- Disable knop als je dataport al verbinding heeft gemaakt met de target dataport -->
                                <div class="modal-footer">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="confirmAuthorizationRequest"
                                        :disabled="authorisationStatus !== null"
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        data-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="nodataportsfound && !loadingSearchResults"
                    class="alert alert-warning mt-4"
                    role="alert"
                >
                    Your search query
                    <span class="font-weight-bold ml-1 mr-1">{{
                        lastSearchQuery
                    }}</span>
                    did not match any DataPort
                    <button
                        @click="focusOnSearch"
                        type="button"
                        class="close"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* global $ */
import { mapGetters } from "vuex";
export default {
    name: "searchEngine",
    data() {
        return {
            searchResults: {},
            nodataportsfound: false,
            searchQuery: "",
            lastSearchQuery: "",
            loadingSearchResults: false,
            canRequestAccess: true,
            requestedAccess: false,
            selectedTargetDataPort: null,
            selectedOwnedDataPort: null,
            authorisationStatus: null,
            authorisationRequestDTO: {},
            searchAuthorisationBasedOnResultsDTO: {},
            searchedDataports: [],
        };
    },

    methods: {
        getDataportResults() {
            let self = this;
            let query = this.searchQuery;

            this.searchAuthorisationBasedOnResultsDTO.organisationId = this.userProfile.organisationId;

            let url =
                "https://apidp.syncforce.com/search/" +
                encodeURIComponent(query) +
                "/dataports";

            if (!query.length) {
                return false;
            } else {
                self.loadingSearchResults = true;
                $.post(url)
                    .done(function (response) {
                        self.searchResults = response.dataports;
                        if (self.searchResults.length === 0) {
                            self.nodataportsfound = true;
                        } else {
                            self.nodataportsfound = false;
                        }
                        self.loadingSearchResults = false;
                        self.lastSearchQuery = query;
                        
                    })
                    .fail(function () {
                        console.error("POST " + url + " FAILED!");
                    });
               
                //this.loadExistingAuthorisation();
            }
             //this.$store.dispatch("authorisation/searchDataPorts",this.searchResults)
        },

        focusOnSearch() {
            this.$refs["inputQuery"].focus();
            this.$refs["inputQuery"].select();
            this.nodataportsfound = false;
        },
        loadExistingAuthorisation() {
            this.searchedDataports = [];
            this.searchResults.forEach((element) => {
                this.searchedDataports.push(element.services[0].dataport);
            });
            this.searchAuthorisationBasedOnResultsDTO.searchedDataports = this.searchedDataports;
            this.$store.dispatch(
                "authorisation/LoadAuthorisationBasedOnSearchResult"(
                    this.searchAuthorisationBasedOnResultsDTO
                )
            );
        },
        requestDataportAccess(data) {
            this.selectedTargetDataPort = data;
            $("#connectDataPortsModal").modal("toggle");
        },
        confirmAuthorizationRequest() {
            this.authorisationRequestDTO.SourceDataPortId = this.selectedOwnedDataPort;
            this.authorisationRequestDTO.DestinationDataPortId = this.selectedTargetDataPort;
            this.$store.dispatch(
                "authorisation/requestAuthorisation",
                this.authorisationRequestDTO
            );

            $("#connectDataPortsModal").modal("toggle");
        },
        checkAuthorisationStatus(event) {
            this.authorisationStatus = null;
            this.selectedOwnedDataPort = event.target.value;

            this.authorizedDataportList.forEach((element) => {
                if (
                    element.sourceDataPortId.toLowerCase() ===
                        this.selectedOwnedDataPort.toLowerCase() &&
                    element.destinationDataPortId.toLowerCase() ===
                        this.selectedTargetDataPort.toLowerCase()
                ) {
                    this.authorisationStatus = element.status;
                }
            });
        },
        startup() {
            let access_token = window.sessionStorage.getItem("vuex");
            if (access_token !== null) {
                // user doesn't have access token, redirect to login
                this.$store.dispatch("user/toggleLoadingMode");
                this.organisationProfileDTO.id = this.userProfile.organisationId;
                this.$store
                    .dispatch(
                        "dataport/loadDataports",
                        this.organisationProfileDTO
                    )
                    .then(() => {
                        return this.$store
                            .dispatch(
                                "authorisation/loadAuthorisedDataports",
                                this.organisationProfileDTO
                            )
                            .then(() => {
                                return this.$store.dispatch(
                                    "authorisation/loadAuthorisedStatusList"
                                );
                            })
                            .finally(
                                this.$store.dispatch("user/toggleLoadingMode")
                            );
                    });
            }
        },
    },

    mounted() {
        //this.startup();
    },
    computed: {
        ...mapGetters({
            authorizationStatusList: "authorisation/getAuthorizationStatusList",
            authorizedDataportList: "authorisation/getAuthorizedDataports",
            userProfile: "user/getUserProfile",
        }),

        connectedDataPorts() {
            return this.$store.state.authorisation.authorisedDataports.length;
        },

        showAuthorisationStatus() {
            if (this.authorisationStatus !== null) {
                let status = this.authorizationStatusList.find(
                    (item) => item.id === this.authorisationStatus
                );
                return status.status.toLowerCase();
            }
            return null;
        },
    },
};
</script>
<style scoped>
img {
    margin: 0 auto;
    display: block;
}

.title {
    font-size: 1.2rem;
}
</style>