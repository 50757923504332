<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="saveAuthorization">
            <div>
                <div class="row">
                    <div class="col-md-12">
                        <router-link to="/authorizations">
                            <a><i class="fas fa-chevron-left"></i>&nbsp;Back</a>
                        </router-link>

                        <button
                            type="button"
                            class="btn btn-success btn-sm float-right"
                            :class="{
                                savedButtonColor: saveButtonText == 'Saved',
                            }"
                            @click="saveAuthorization"
                        >
                            <i class="fas fa-save"></i>&nbsp;{{
                                saveButtonText
                            }}
                        </button>
                    </div>

                    <div class="col-md-6 mt-4 mb-4">
                        <div class="form-group">
                            <label for="id"> DataPort Source Address</label>
                            <input
                                type="text"
                                class="form-control"
                                id="dataPortSourceId"
                                placeholder="DataPort Address"
                                aria-describedby="id"
                                v-model="selectedAuthorisation.sourceDataPortId"
                                readonly
                            />
                        </div>
                        <ValidationProvider
                            name="Public Reference"
                            rules="required|alpha_spaces"
                            v-slot="v"
                        >
                            <div class="form-group">
                                <label for="pubRef"
                                    >DataPort Name</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    id="pubRef"
                                    placeholder="Public Reference"
                                    aria-describedby="pubRef"
                                    v-model="selectedAuthorisation.sourceDataPortName"
                                    readonly
                                />
                            </div>
                            <span class="text-danger">
                                {{ v.errors[0] }}
                            </span>
                        </ValidationProvider>
                        <div class="form-group">
                            <label for="id">DataPort Target Adress</label>
                            <input
                                type="text"
                                class="form-control"
                                id="id"
                                placeholder="Owner"
                                aria-describedby="id"
                                v-model="selectedAuthorisation.destinationDataPortId"
                                readonly
                            />
                        </div>
                        <ValidationProvider
                            name="Internal Reference"
                            rules="required|alpha_spaces"
                            v-slot="v"
                        >
                            <div class="form-group">
                                <label for="intRef"
                                    >DataPort Target Name</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    id="intRef"
                                    placeholder="Internal Reference"
                                    aria-describedby="intRef"
                                    v-model="selectedAuthorisation.destinationDataPortName"
                                    readonly
                                />
                            </div>
                            <span class="text-danger">
                                {{ v.errors[0] }}
                            </span>
                        </ValidationProvider>
                        <div class="form-group">
                            <label for="status">Status</label>
                            <select
                                class="form-control"
                                id="status"
                                name="status"
                                v-model="selectedAuthorisation.status"
                            >
                                <option disabled selected>
                                     {{translateStatus(selectedAuthorisation.status)}}
                                </option>
                                <option
                                    v-for="(status, index) in $store.state
                                        .authorisation.authorisationStatusList"
                                    :key="index"
                                    :value="status.id"
                                >
                                   {{ status.status }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, alpha_spaces } from "vee-validate/dist/rules";

extend("alpha_spaces", {
    ...alpha_spaces,
    message: "Only alphabetic characters and spaces are allowed",
});

extend("required", {
    ...required,
});
export default {
    name: "editAuthorization",
    data() {
        return {
            saveButtonText: "Save",
            backupDataPort: {},
            dataPortDTO: {},
        };
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        id: String,
    },
    methods: {
        saveAuthorization() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    return;
                }

                this.$store.dispatch("authorisation/updateAuthorisation", this.selectedAuthorisation);
                this.saveButtonText = "Saved";
                setTimeout(() => (this.saveButtonText = "Save"), 2000);
            });
        },
        translateStatus(sId) {
            return this.statuses.find((status) => status.id === sId).status;
        },

    },

    mounted() {
        console.log(this.id)
        console.log(this.selectedAuthorisation)
    },
    computed: {
        ...mapGetters({
            statuses: "authorisation/getAuthorizationStatusList",
            userprivilege: "user/getUserPrivilege",
            organisationsProfile: "organisation/getOrganisationProfile",
        }),
        selectedAuthorisation() {
            return this.$store.getters["authorisation/getAuthorisationById"](Number(this.id));
        },
    },
    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>
<style scoped>
</style>