<template>
    <div>
        <div class="row justify-content-center mt-5">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <router-link to="/dataportsWebsite">
                    <a> <i class="fas fa-chevron-left"></i>&nbsp;Back</a>
                </router-link>
                <div class="card mt-3">
                    <div class="card-body">
                        <div class="form-group">
                            <div class="signup-header">Set your password</div>
                            <ul class="list-unstyled text-muted">
                                <li
                                    v-bind:class="{
                                        isValid: containsEightCharacters,
                                    }"
                                >
                                    At least 8 characters
                                </li>
                                <li
                                    v-bind:class="{
                                        isValid: containsNumber,
                                    }"
                                >
                                    Contains a number
                                </li>
                                <li
                                    v-bind:class="{
                                        isValid: containsUppercase,
                                    }"
                                >
                                    Contains an uppercase character
                                </li>
                                <li
                                    v-bind:class="{
                                        isValid: containsSpecialCharacter,
                                    }"
                                >
                                    Contains a special character
                                </li>
                            </ul>
                            <div class="input-group-append">
                                <input
                                    id="setNewPassword"
                                    class="form-control remove-input-border"
                                    type="password"
                                    autocomplete="new-password"
                                    @input="checkPassword"
                                    v-model="password"
                                    ref="passwordInput"
                                    placeholder="Set a password"
                                />
                                <span
                                    @click="togglePassword"
                                    class="input-group-append input-group-text remove-addon-styling"
                                >
                                    <div
                                        v-show="!showPassword"
                                        class="password-toggle"
                                    >
                                        <i class="fas fa-eye-slash"></i>
                                    </div>
                                    <div
                                        v-show="showPassword"
                                        class="password-toggle"
                                    >
                                        <i class="fas fa-eye text-primary"></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <button
                            type="submit"
                            class="btn btn-primary float-right"
                            :disabled="!validPassword"
                            @click="setPassword"
                        >
                            Set password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "setPassword",
    data() {
        return {
            password: null,
            confirmPassword: null,
            password_length: 0,
            containsEightCharacters: false,
            containsNumber: false,
            containsUppercase: false,
            containsSpecialCharacter: false,
            validPassword: false,
            showPassword: false,
            creationDTO: {},
        };
    },
    methods: {
        checkPassword() {
            this.password_length = this.password.length;
            const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

            if (this.password_length > 8) {
                this.containsEightCharacters = true;
            } else {
                this.containsEightCharacters = false;
            }

            this.containsNumber = /\d/.test(this.password);
            this.containsUppercase = /[A-Z]/.test(this.password);
            this.containsSpecialCharacter = format.test(this.password);

            if (
                this.containsEightCharacters === true &&
                this.containsSpecialCharacter === true &&
                this.containsUppercase === true &&
                this.containsNumber === true
            ) {
                this.validPassword = true;
            } else {
                this.validPassword = false;
            }
        },
        setPassword() {
            this.creationDTO = JSON.parse(
                window.sessionStorage.getItem("Creation")
            );
            this.creationDTO.password = this.password;
            this.$store
                .dispatch(
                    "organisation/registerOrganisationAndUser",
                    this.creationDTO
                )
                .then(() => this.$router.push("/dashboard"));
        },
        togglePassword() {
            var x = this.$refs["passwordInput"];
            if (x.type === "password") {
                x.type = "text";
                this.showPassword = true;
            } else {
                x.type = "password";
                this.showPassword = false;
            }
        },
    },
    mounted() {},
};
</script>

<style scoped>
.remove-addon-styling {
    background-color: #fff;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.remove-input-border {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.password-toggle:hover {
    cursor: pointer;
}

ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

li {
    margin-bottom: 8px;
    position: relative;
}

li:before {
    content: "";
    width: 0%;
    height: 2px;
    background: #2ecc71;
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
/* Checkmark & Strikethrough --------- */

.isValid {
    color: rgba(136, 152, 170, 0.8);
}
.isValid:before {
    width: 100%;
}

.checked {
    animation: draw 0.5s ease forwards;
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
</style>