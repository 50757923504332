<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="saveDatePort">
            <div>
                <div class="row" v-if="userprivilege.editDataPort">
                    <div class="col-md-12">
                        <router-link to="/myDataports">
                            <a><i class="fas fa-chevron-left"></i>&nbsp;Back</a>
                        </router-link>

                        <button
                            type="button"
                            class="btn btn-success btn-sm float-right"
                            :class="{
                                savedButtonColor: saveButtonText == 'Saved',
                            }"
                            @click="saveDatePort"
                        >
                            <i class="fas fa-save"></i>&nbsp;{{
                                saveButtonText
                            }}
                        </button>
                        <button
                            v-if="!selectedDataport.archived"
                            type="button"
                            class="btn btn-primary btn-sm float-right mr-1"
                            :disabled="userprivilege.archiveDataPort == false"
                            @click="archiveDataportToggle"
                        >
                            <i class="fas fa-archive"></i>&nbsp;Archive
                        </button>
                        <button
                            v-else
                            type="button"
                            class="btn btn-primary btn-sm float-right mr-1"
                            @click="archiveDataportToggle"
                        >
                            <i class="fas fa-folder-open"></i>&nbsp;Unarchive
                        </button>
                    </div>

                    <div
                        class="col-md-6 mt-4 mb-4"
                        v-if="!selectedDataport.archived"
                    >
                        <div class="form-group">
                            <label for="id">ID</label>
                            <input
                                type="text"
                                class="form-control"
                                id="id"
                                placeholder="DataPort ID"
                                aria-describedby="id"
                                readonly
                                v-model="selectedDataport.id"
                            />
                        </div>
                        <ValidationProvider
                            name="Public Reference"
                            rules="required|alpha_spaces"
                            v-slot="v"
                        >
                            <div class="form-group">
                                <label for="pubRef"
                                    >Public Reference<span class="text-danger"
                                        >*</span
                                    ></label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    id="pubRef"
                                    placeholder="Public Reference"
                                    aria-describedby="pubRef"
                                    v-model="selectedDataport.reference"
                                />
                            </div>
                            <span class="text-danger">
                                {{ v.errors[0] }}
                            </span>
                        </ValidationProvider>
                        <ValidationProvider
                            name="Internal Reference"
                            rules="required|alpha_spaces"
                            v-slot="v"
                        >
                            <div class="form-group">
                                <label for="intRef"
                                    >Internal Reference<span class="text-danger"
                                        >*</span
                                    ></label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    id="intRef"
                                    placeholder="Internal Reference"
                                    aria-describedby="intRef"
                                    v-model="selectedDataport.internalReference"
                                />
                            </div>
                            <span class="text-danger">
                                {{ v.errors[0] }}
                            </span>
                        </ValidationProvider>
                        <div class="form-group">
                            <label for="owner">Owner</label>
                            <input
                                class="form-control"
                                id="owner"
                                name="selectOwner"
                                v-model="organisationsProfile.organisationName"
                                readonly
                                value="organisationsProfile.organisationName"
                            />

                            <!-- <option disabled selected>
                            
                            {{ selectedDataport.name }}
                        </option> -->
                            <!-- <option
                            v-for="(owner, index) in $store.state.dataport
                                .owners"
                            :key="index"
                            :value="selectedDataport.name"
                        >
                            {{ translateOwner(owner.id) }}
                        </option> -->
                        </div>
                        <div class="form-group">
                            <label for="listType">Listing Type</label>
                            <select
                                class="form-control"
                                id="listType"
                                name="listType"
                                v-model="selectedDataport.listing"
                            >
                                <option disabled selected>
                                    {{ selectedDataport.listing }}
                                </option>
                                <option
                                    v-for="(listing, index) in $store.state
                                        .dataport.listings"
                                    :key="index"
                                    :value="listing.value"
                                >
                                    {{ listing.value }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="status">Status</label>
                            <select
                                class="form-control"
                                id="status"
                                name="status"
                                v-model="selectedDataport.status"
                            >
                                <option disabled selected>
                                    {{
                                        translateStatus(selectedDataport.status)
                                    }}
                                </option>
                                <option
                                    v-for="(status, index) in $store.state
                                        .dataport.statuses"
                                    :key="index"
                                    :value="status.id"
                                >
                                    {{ status.value }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6 mt-4 mb-4" v-else>
                        <div class="form-group">
                            <label for="id">ID</label>
                            <input
                                type="text"
                                class="form-control"
                                id="id"
                                placeholder="DataPort ID"
                                aria-describedby="id"
                                v-model="selectedDataport.address"
                                readonly
                            />
                        </div>
                        <div class="form-group">
                            <label for="pubRef">Public Reference</label>
                            <input
                                type="text"
                                class="form-control"
                                id="pubRef"
                                placeholder="Public Reference"
                                aria-describedby="pubRef"
                                v-model="selectedDataport.reference"
                                readonly
                            />
                        </div>
                        <div class="form-group">
                            <label for="intRef">Internal Reference</label>
                            <input
                                type="text"
                                class="form-control"
                                id="intRef"
                                placeholder="Internal Reference"
                                aria-describedby="intRef"
                                v-model="selectedDataport.internalReference"
                                readonly
                            />
                        </div>
                        <div class="form-group">
                            <label for="owner">Owner</label>
                            <input
                                type="text"
                                class="form-control"
                                id="owner"
                                placeholder="No owner"
                                aria-describedby="owner"
                                :value="organisationsProfile.organisationName"
                                readonly
                            />
                        </div>
                        <div class="form-group">
                            <label for="listType">Listing Type</label>
                            <input
                                type="text"
                                class="form-control"
                                id="listing"
                                placeholder="No listing type"
                                aria-describedby="listing"
                                :value="selectedDataport.listing"
                                readonly
                            />
                        </div>
                        <div class="form-group">
                            <label for="status">Status</label>
                            <input
                                type="text"
                                class="form-control"
                                id="status"
                                placeholder="No status"
                                aria-describedby="status"
                                :value="
                                    translateStatus(selectedDataport.status)
                                "
                                readonly
                            />
                        </div>
                    </div>
                </div>

                <div v-else class="mt-4">
                    <div class="alert alert-primary" role="alert">
                        You're not authorized to edit DataPorts.
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, alpha_spaces } from "vee-validate/dist/rules";

extend("alpha_spaces", {
    ...alpha_spaces,
    message: "Only alphabetic characters and spaces are allowed",
});

extend("required", {
    ...required,
});
export default {
    name: "editMyDataport",
    data() {
        return {
            saveButtonText: "Save",
            backupDataPort: {},
            dataPortDTO: {},
        };
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        id: String,
    },
    methods: {
        saveDatePort() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    return;
                }
                this.dataPortDTO.id = this.selectedDataport.id;
                this.dataPortDTO.secretKey = this.selectedDataport.secretKey;
                this.dataPortDTO.internalReference = this.selectedDataport.internalReference;
                this.dataPortDTO.reference = this.selectedDataport.reference;
                this.dataPortDTO.name = this.selectedDataport.name;
                this.dataPortDTO.listing = this.selectedDataport.listing;
                this.dataPortDTO.status = this.selectedDataport.status;
                this.dataPortDTO.created = this.selectedDataport.created;
                this.dataPortDTO.address = this.selectedDataport.address;
                this.dataPortDTO.archived = this.selectedDataport.archived;
                this.$store.dispatch("dataport/editDataport", this.dataPortDTO);
                this.saveButtonText = "Saved";
                setTimeout(() => (this.saveButtonText = "Save"), 2000);
            });
        },
        translateStatus(sId) {
            return this.statuses.find((status) => status.id === sId).value;
        },
        translateOwner(oId) {
            const temp = this.$store.getters["dataport/getOwners"];
            return temp.find((owner) => owner.id === parseInt(oId)).value;
        },
        archiveDataportToggle() {
            this.selectedDataport.reference = this.backupDataPort.reference;
            this.selectedDataport.internalReference = this.backupDataPort.internalReference;
            this.selectedDataport.listing = this.backupDataPort.listing;

            this.dataPortDTO.id = this.selectedDataport.id;
            this.dataPortDTO.secretKey = this.selectedDataport.secretKey;
            this.dataPortDTO.internalReference = this.selectedDataport.internalReference;
            this.dataPortDTO.reference = this.selectedDataport.reference;
            this.dataPortDTO.listing = this.selectedDataport.listing;
            this.dataPortDTO.name = this.selectedDataport.name;
            this.dataPortDTO.created = this.selectedDataport.created;
            this.dataPortDTO.address = this.selectedDataport.address;
            this.$store
                .dispatch("dataport/toggleDataportArchive", this.id)
                .then(
                    () =>
                        (this.dataPortDTO.status = this.selectedDataport.status),
                    (this.dataPortDTO.archived = this.selectedDataport.archived)
                )
                .then(() =>
                    this.$store.dispatch(
                        "dataport/editDataport",
                        this.dataPortDTO
                    )
                );
        },
        startUp() {
            
        },
    },
    mounted() {
        this.startUp();
    },

    computed: {
        ...mapGetters({
            listings: "dataport/getListings",
            statuses: "dataport/getStatuses",
            userProfile: "user/getUserProfile",
            userprivilege: "user/getUserPrivilege",
            organisationsProfile: "organisation/getOrganisationProfile",
        }),
        selectedDataport() {
            return this.$store.getters["dataport/getDataportById"](this.id);
        },
    },
    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>
<style scoped>
.savedButtonColor {
    background-color: #17a2b8;
    border-color: #17a2b8;
}
</style>