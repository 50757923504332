<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <span class="badge badge-danger">Sample Data</span>
            </div>
        </div>
        <div class="row">
            <!-- Iedere grafiek opslaan als apart component? Maakt het overzichtelijker -->
            <div class="col-md-8 col-sm-12">
                <!-- data ophalen mbv v-for (for each type chart? Voor alle dashboard grafieken? -->
                <div class="content-header">DataContainer stats</div>
                <div class="card">
                    <div class="card-body">
                        <!-- vervangen met line chart; https://apexcharts.com/javascript-chart-demos/line-charts/basic/ -->
                        <VueApexCharts
                            type="area"
                            height="350"
                            :options="chartOptions"
                            :series="series"
                        ></VueApexCharts>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12 mobile-margin-top">
                <div class="content-header">DataPort status overview</div>
                <div class="card">
                    <div class="card-body">
                        <VueApexCharts
                            type="pie"
                            height="350"
                            :options="chartOptionsPie"
                            :series="formPieChart"
                        ></VueApexCharts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
export default {
    name: "dashboard",
    components: {
        VueApexCharts,
    },
    data() {
        return {
            series: [
                {
                    name: "Sent",
                    data: [],
                },
                {
                    name: "Received",
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: "area",
                    zoom: {
                        type: "x",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth",
                },
                xaxis: {
                    type: "datetime",
                    categories: [],
                },

                tooltip: {
                    x: {
                        format: "dd/MM/yy HH:mm",
                    },
                },
                colors: ["#008FFB", "#17A2B8"],
            },
            chartOptionsPie: {
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: ["Active", "Inactive"],
                colors: ["#28A745", "#FFC107"],
            },
        };
    },
    methods: {
        startup() {
            this.generateFakeData();
        },
        //refactor this after pilot
        getDataPortStatus() {
            var organisationDTO = {};
            organisationDTO.id = this.userProfile.organisationId;
            this.$store.dispatch("user/toggleLoadingMode");
            this.$store
                .dispatch("dataport/loadDataports", organisationDTO)
                .then(() => {
                    if (!this.dataportList.length) {
                        var data1 = {
                            id: "",
                            secretKey: "",
                            reference: "",
                            name: "",
                            internalReference: "",
                            listing: "",
                            status: 1,
                            created: "",
                            address: "",
                            archived: false,
                        };
                        var data2 = {
                            id: "",
                            secretKey: "",
                            reference: "",
                            name: "",
                            internalReference: "",
                            listing: "",
                            status: 1,
                            created: "",
                            address: "",
                            archived: false,
                        };
                        var data3 = {
                            id: "",
                            secretKey: "",
                            reference: "",
                            name: "",
                            internalReference: "",
                            listing: "",
                            status: 1,
                            created: "",
                            address: "",
                            archived: false,
                        };
                        var data4 = {
                            id: "",
                            secretKey: "",
                            reference: "",
                            name: "",
                            internalReference: "",
                            listing: "",
                            status: 0,
                            created: "",
                            address: "",
                            archived: false,
                        };
                        this.dataportList.push(data1);
                        this.dataportList.push(data2);
                        this.dataportList.push(data3);
                        this.dataportList.push(data4);
                        
                    }
                })
                .finally(this.$store.dispatch("user/toggleLoadingMode"));
        },

        generateFakeData() {
            console.log("test")
            this.currentDate = new Date();
            for (
                let months = 1;
                months <= this.currentDate.getMonth() + 1;
                months++
            ) {
                for (let days = 1; days < 28; days++) {
                    if (
                        days > this.currentDate.getDate() &&
                        months >= this.currentDate.getMonth() + 1
                    ) {
                        return;
                    }
                    const value = months + "/" + days + "/20 00:00";
                    this.chartOptions.xaxis.categories.push(value);
                    const send = Math.floor(Math.random() * 30);
                    this.series[0].data.push(send);
                    const recieve = Math.floor(Math.random() * 30);
                    this.series[1].data.push(recieve);
                }
            }
        },
    },

    created() {
        this.startup();
    },
    mounted() {
        this.getDataPortStatus();
    },

    computed: {
        ...mapGetters({
            dataportList: "dataport/getDataports",
            userProfile: "user/getUserProfile",
        }),
        getActiveDataports() {
            let activeList = this.dataportList.filter((dataport) => {
                return dataport.status == 1;
            });
            return activeList.length;
        },
        getIdleDataports() {
            let activeList = this.dataportList.filter((dataport) => {
                return dataport.status == 0;
            });
            return activeList.length;
        },
        formPieChart() {
            let piechart = [];
            piechart[0] = this.getActiveDataports;
            piechart[1] = this.getIdleDataports;
            return piechart;
        },
    },

    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>

<style scoped>
</style>