<template>
    <div>
        <nav
            class="col-md-2 navbar-expand-md fixed-top self-service-portal-sidenav"
        >
            <button
                class="navbar-toggler mobile-menu-button mt-2"
                type="button"
                data-toggle="collapse"
                data-target="#mainNavigation"
                aria-controls="mainNavigation"
            >
                <i class="fas fa-bars"></i>
            </button>

            <div class="dp-logo mb-md-5 mt-md-5 mb-sm-2 mt-sm-2">
                <router-link to="/dashboard">
                    <img src="../assets/dp-logo-white.png" />
                </router-link>
            </div>

            <div class="collapse navbar-collapse" id="mainNavigation">
                <ul class="list-unstyled">
                    <li class="navbar-link mb-2">
                        <router-link to="/dashboard">
                            <i class="fas fa-tachometer-alt icon-padding"></i>
                            <div class="nav-label">Dashboard</div>
                        </router-link>
                    </li>
                    <li class="navbar-link mb-2">
                        <router-link to="/myDataports">
                            <span class="dp-icon">
                                <img src="../assets/dp-logo-white.png" />
                            </span>
                            <div class="nav-label">My Dataports</div>
                        </router-link>
                    </li>
                    <li class="navbar-link mb-2">
                        <router-link to="/authorizations">
                            <i class="fas fa-shield-alt icon-padding"></i>
                            <div class="nav-label">Authorizations</div>
                        </router-link>
                    </li>
                    <li class="navbar-link mb-2">
                        <router-link to="/downloads">
                            <i
                                class="fas fa-cloud-download-alt icon-padding"
                            ></i>
                            <div class="nav-label">Downloads</div>
                        </router-link>
                    </li>
                    <li class="navbar-link mb-2">
                        <router-link to="/organisation">
                            <i class="fas fa-building icon-padding"></i>
                            <div class="nav-label">Organisation</div>
                        </router-link>
                    </li>
                    <li class="navbar-link mb-2" v-if="userProfile.admin">
                        <router-link to="/manageUsers">
                            <i class="fas fa-users-cog icon-padding"></i>
                            <div class="nav-label">Manage users</div>
                        </router-link>
                    </li>
                    <hr />
                    <li class="navbar-link">
                        <router-link to="/searchEngine">
                            <i class="fas fa-search icon-padding"></i>
                            <div class="nav-label">search engine</div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
/* global $ */
import { mapGetters } from "vuex";
export default {
    name: "sideNav",
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    mounted() {
        window.addEventListener("resize", () => {
            this.windowWidth = window.innerWidth;
        });
    },
    computed: {
        ...mapGetters({
            userProfile: "user/getUserProfile",
        }),
    },
    watch: {
        $route() {
            if (this.windowWidth <= 768) {
                $(".collapse").collapse("hide");
            }
        },
    },
};
</script>

<style scoped>
.dp-logo {
    display: block;
}
.dp-logo img {
    width: 120px;
    height: auto;
}
.self-service-portal-sidenav {
    min-height: calc(100vh);
    background-color: #1d344d;
    color: #fff;
}
.nav-label {
    display: inline-block;
    opacity: 0.6;
}
.nav-label:hover {
    opacity: 1;
}
.router-link-active .nav-label {
    opacity: 1;
}
.self-service-portal-sidenav li {
    text-transform: uppercase;
    font-size: 16px;
}
.self-service-portal-sidenav li a {
    color: #fff;
}
.self-service-portal-sidenav li .dp-icon img {
    width: 18px;
    height: auto;
    margin-right: 5px;
}
.self-service-portal-sidenav li a .icon-padding {
    width: 18px;
    margin-right: 5px;
}
.navigation-label {
    display: inline-block;
}
.mobile-menu-button {
    background-color: #1d344d;
    border-color: #fff;
    color: #fff;
    margin-top: 5px;
}

.self-service-portal-sidenav hr {
    border-top: 1px solid rgb(255 255 255 / 20%);
}

@media only screen and (max-width: 992px) {
    .nav-label {
        display: block;
    }
}

@media only screen and (max-width: 767px) {
    .dp-logo {
        margin-top: 5px;
        margin-bottom: 0;
        float: right;
    }
    .dp-logo img {
        width: 30px;
    }
    .self-service-portal-sidenav {
        padding-left: 15px;
        padding-bottom: 5px;
        min-height: auto;
    }
    .nav-label {
        display: inline-block;
    }
    .self-service-portal-sidenav .navbar-collapse {
        margin-top: 20px;
    }
}
</style>