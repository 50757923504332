import api from '../apis/api.js';

const dataport = {
    namespaced: true,
    state: {
        dataport: {
            id: "",
            secretKey: "",
            reference: "",
            name: "",
            internalReference: "",
            listing: "",
            status: "",
            created: "",
            address: "",
            archived: false,
        },
        dataports: [],
        authorisedDataports: [],
        newDataportHolder: {
            address: "",
            secretKey: ""
        },
        statuses: [{
                value: "Idle",
                id: 0
            }, {
                value: "Active",
                id: 1
            },

        ],
        listings: [{
                value: "Private",
                id: 1
            },
            {
                value: "Public",
                id: 2
            },
        ],
        selectedDataport: {},
        
    },

    // getters
    getters: {
        getDataports(state) {
            return state.dataports;
        },
        getDataportById: (state) => (id) => {
            return state.dataports.find(dp => dp.id === id);
        },
        getNewDataport(state) {
            return state.newDataportHolder;
        },
        getOwners(state) {
            return state.owners;
        },
        getStatuses(state) {
            return state.statuses;
        },
        getListings(state) {
            return state.listings;
        },
        getSelectedDataport(state) {
            return state.selectedDataport;
        },
        getAuthorizedDataports(state) {
            return state.authorisedDataports;
        }

    },

    // actions

    actions: {
        async loadDataports(state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/GetDataportsByOwner", DTO).then(response => {
                state.commit("updateDataports", response.data);
            });
        },
        //temp get all dataports needs to be changed to POST when authorized table in database is filled
        async loadAuthorisedDataports(state) {
            return api.methods.sentGetRequestNoBody("/dataports").then(response => {
                state.commit("updateAuthorisedDataports", response.data);
            });
        },
        async newDataport(state) {
            return api.methods.sentGetRequestNoBody("/NewDataport").then(response => {
                state.commit("updateNewDataportHolder", response.data);
            })
        },
        async addDataport(state, DTO) {
            await state.commit("updateDTO", DTO)
            return api.methods.sentPOSTRequestWithBody("/AddDataport", DTO)
        },
        async editDataport(state, DTO) {
            state.commit("updateDataport", DTO)
            return api.methods.sentPOSTRequestWithBody("/EditDataport", DTO);
        },
        async archiveDataport(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/archivedataport", DTO);
        },
        async toggleDataportEditMode(state, index) {
            await state.commit("updateDataportEditMode", index)
        },
        async toggleDataportArchive(state, index) {
            await state.commit("updateDataportArchive", index)
        },
        async selectedDataport(state, dp) {
            await state.commit("updateSelectDataport", dp)
        },
        async setSelectedDataport(state, id) {
            await state.commit("updateSelectDataport", id)
        }

    },

    // mutations
    mutations: {
        setLoading(state, loading) {
            state.isLoading = loading
        },
        updateDataport(state, index, DTO) {
            state.dataports[index] = DTO
        },
        updateDTO(state, DTO) {
            state.dataportDTO = DTO;
        },

        updateNewDataportHolder(state, data) {
            state.newDataportHolder = data;
        },
        updateDataportDTO(state, data) {
            state.dataportDTO = data;
        },
        removeDataport(state, index) {
            state.dataports.splice(index, 1);
        },
        translateOwnerNames(state, dataports) {
            for (let index = 0; index < dataports.length; index++) {
                dataports[index].editMode = false;
            }
            state.dataports = dataports;
        },
        updateDataports(state, dataports) {
            state.dataports = dataports
        },
        updateDataportEditMode(state, id) {
            let dataport = state.dataports.find(dp => dp.id === id);
            if (dataport) dataport.editMode = !dataport.editMode;

        },
        updateDataportArchive(state, id) {
            let dataport = state.dataports.find(dp => dp.id === id)
            dataport.archived = !dataport.archived;
            if (dataport.archived === true) {
                state.dataports.find(dp => dp.id === id).status = 0;
            }

        },
        updateAuthorisedDataports(state, data) {
            state.authorisedDataports = [];
            for (let x = 0; x < state.dataports.length; x++) {
                var temp = JSON.parse(JSON.stringify(data));
                var target = state.dataports[x].reference
                var targetaddress = state.dataports[x].address;
                for (let y = 0; y < temp.length; y++) {

                    if (state.dataports[x].address !== temp[y].address) {

                        temp[y].target = target;
                        temp[y].targetDataport = targetaddress;
                        state.authorisedDataports.push(temp[y])
                    }
                }
            }
        },
        updateSelectDataport(state, id) {
            var dp = state.dataports.find(dp => dp.id === id);
            state.selectedDataport = dp;
        }

    },
}

export default dataport