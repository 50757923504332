<template>
    <div v-on-clickaway="deactiveProfileThumbnail">
        <div v-if="userProfile.profileImage">
            <img
                :src="userProfile.profileImage"
                alt="profile-picture"
                class="avatar float-right"
                @click="toggleProfile"
            />
        </div>
        <div v-else class="avatar float-right" @click="toggleProfile">
            <i class="fas fa-user avatar-no-pic"></i>
        </div>
        <div class="profile">
            <div class="card mt-2 float-right" v-show="profileActive">
                <div class="card-body">
                    <div class="profile-image float-left mr-2">
                        <div v-if="userProfile.profileImage">
                            <img
                                :src="userProfile.profileImage"
                                alt="profile-picture"
                            />
                        </div>
                        <div
                            v-else
                            class="avatar float-left mr-2"
                            @click="toggleProfile"
                        >
                            <i class="fas fa-user avatar-no-pic"></i>
                        </div>
                    </div>

                    <div class="profile-info">
                        <h5 class="card-title mb-1">
                            {{ this.userProfile.name }}
                        </h5>
                        <p class="card-text mb-2">
                            <i class="fas fa-envelope mr-1"></i>
                            <span class="profile-content">{{
                                this.userProfile.email
                            }}</span>
                        </p>
                        <p class="card-subtitle">
                            <i class="fas fa-phone-alt mr-1"></i>
                            {{ this.userProfile.phoneNumber }}
                        </p>
                        <div class="mt-2">
                            <router-link
                                to="/myAccount"
                                @click.native="deactiveProfileThumbnail"
                            >
                                <i class="fas fa-id-badge mr-1"></i>My Account
                            </router-link>
                        </div>
                    </div>

                    <hr />
                    <div class="organisation-image float-left mr-2">
                        <i class="fas fa-building"></i>
                    </div>
                    <div class="profile-info">
                        <h5
                            v-if="this.organisationsProfile.name"
                            class="card-title mb-1"
                        >
                            {{ this.organisationsProfile.name }}
                        </h5>
                        <p class="card-text mb-1">
                            <span>
                                <i class="fas fa-map-marker-alt mr-1"></i>
                            </span>
                            {{ this.organisationsProfile.countryHQ }}
                        </p>
                        <div
                            v-if="this.organisationsProfile.GPN"
                            class="input-group input-group-sm mb-1"
                        >
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="addon"
                                    >GPN</span
                                >
                            </div>
                            <input
                                type="text"
                                placeholder="GPN"
                                readonly="readonly"
                                v-model="
                                    this.organisationsProfile.GPN
                                "
                                class="form-control"
                            />
                        </div>
                        <div
                            v-if="this.organisationsProfile.VATId"
                            class="input-group input-group-sm"
                        >
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="addon"
                                    >VAT ID</span
                                >
                            </div>
                            <input
                                type="text"
                                placeholder="VAT ID"
                                readonly="readonly"
                                v-model="this.organisationsProfile.VATId"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="mt-2 text-center" @click="signOut()">
                        <a href="">
                            <i class="fas fa-sign-out-alt mr-1"></i>Sign out
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import { mapGetters } from "vuex";
export default {
    name: "profileThumbnail",
    mixins: [clickaway],
    data() {
        return {
            profileActive: false,
            hasImage: true,
            profileImage: null,
        };
    },
    methods: {
        toggleProfile() {
            this.profileImage = this.userProfile.profileImage;
            this.profileActive = !this.profileActive;
        },
        deactiveProfileThumbnail() {
            if (this.profileActive) {
                this.profileActive = false;
            }
        },
        signOut() {
            window.sessionStorage.clear();
            this.$nextTick(() => {
                this.$router.push("/dataportWebsite");
            });
        },
    },

    computed: {
        ...mapGetters({
            userProfile: "user/getUserProfile",
            organisationsProfile: "organisation/getOrganisationProfile",
        }),
    },
};
</script>
<style scoped>
.avatar {
    width: 50px;
    height: 50px;
    border: 2px solid #1d344d;
    border-radius: 50%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d344d;
}
.avatar:hover {
    cursor: pointer;
}
.avatar-no-pic {
    font-size: 18px;
    color: #fff;
}
.profile {
    position: fixed;
    z-index: 99;
    right: 0;
    margin-top: 50px;
    margin-right: 15px;
}
.profile .card {
    width: 450px;
}
.profile-info {
    display: inline-block;
    width: 76%;
}
.profile-content {
    word-break: break-all;
}
.profile-image {
    display: inline-block;
    width: 50px;
    height: 50px;
}
.profile-image img {
    height: auto;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 2px solid #1d344d;
    object-fit: cover;
}
.organisation-image {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #1d344d;
    border-radius: 50%;
}
.organisation-image .fa-building {
    color: #fff;
    display: block;
    font-size: 1.5em;
    margin: 25% auto;
}
.profile-gpn .fa-copy {
    cursor: pointer;
    color: #007bff;
}

@media only screen and (max-width: 768px) {
    .profile {
        margin-top: 50px;
    }
}
</style>