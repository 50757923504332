<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <!-- <router-link to="/addDataport" v-if="userPrivilege.addDataPort==='Enabled'">
                    <button type="button" class="btn btn-success btn-sm float-right" >
                        <i class="fas fa-plus"></i>&nbsp;DataPort
                    </button>
                </router-link> -->
                <div
                    class="input-group input-group-sm float-right search-input"
                >
                    <input
                        type="text"
                        class="form-control border-right-0"
                        placeholder="Search DataPort"
                        v-model="searchDataports"
                    />
                    <div class="input-group-append">
                        <span class="input-group-text remove-addon-styling">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="float-right mr-1">
                    <div class="active-switch">
                        <span class="mr-2">Show idle DataPorts</span>
                        <label class="switch">
                            <input
                                type="checkbox"
                                @click="toggleInactiveDataPorts"
                                v-model="showInactiveChecked"
                            />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="dataports-table table-responsive mt-4"
            v-if="filteredList.length !== 0"
        >
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>DataPort Address</th>
                        <th>Public Reference</th>
                        <th>Owner</th>
                        <th>Internal Reference</th>
                        <th>Listing Type</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="dataport in filteredList" :key="dataport.id">
                        <td>
                            <span v-show="!dataport.editMode">{{
                                dataport.id
                            }}</span>
                            <input
                                v-show="dataport.editMode"
                                class="form-control form-control-sm"
                                v-model="dataport.id"
                                type="text"
                                placeholder="DataPort address"
                                readonly
                            />
                        </td>
                        <td>
                            <span v-show="!dataport.editMode">{{
                                dataport.reference
                            }}</span>
                            <input
                                v-show="dataport.editMode"
                                class="form-control form-control-sm"
                                type="text"
                                placeholder="Public reference"
                                v-model="dataport.reference"
                            />
                        </td>
                        <td>
                            <span v-show="!dataport.editMode">{{
                                dataport.name
                            }}</span>
                            <select
                                v-show="dataport.editMode"
                                class="form-control form-control-sm"
                                id="Owner"
                                name="selectOwner"
                                v-model="dataport.name"
                            >
                                <option disabled selected>
                                    {{ dataport.name }}
                                </option>
                                <option
                                    v-for="(owner, index) in $store.state
                                        .dataport.owners"
                                    :key="index"
                                    :value="owner.id"
                                >
                                    {{ dataport.name }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <span v-show="!dataport.editMode">{{
                                dataport.internalReference
                            }}</span>
                            <input
                                v-show="dataport.editMode"
                                class="form-control form-control-sm"
                                type="text"
                                placeholder="Internal reference"
                                v-model="dataport.internalReference"
                            />
                        </td>
                        <td>
                            <span v-show="!dataport.editMode">{{
                                dataport.listing
                            }}</span>
                            <select
                                v-show="dataport.editMode"
                                class="form-control form-control-sm"
                                id="listing"
                                name="selectListing"
                                v-model="dataport.listing"
                            >
                                <option value disabled selected>
                                    {{ dataport.listing }}
                                </option>
                                <option
                                    v-for="(listing, index) in $store.state
                                        .dataport.listings"
                                    :key="index"
                                    :value="listing.value"
                                >
                                    {{ listing.value }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <div v-show="!dataport.editMode">
                                <div v-if="dataport.status == 1">
                                    <span class="badge badge-success">
                                        <i class="fas fa-shield-alt"></i
                                        >&nbsp;Active
                                    </span>
                                </div>
                                <div v-else-if="dataport.status == 0">
                                    <span class="badge badge-warning">
                                        <i class="fas fa-hourglass-half"></i
                                        >&nbsp;Idle
                                    </span>
                                </div>
                            </div>
                            <select
                                v-show="dataport.editMode"
                                class="form-control form-control-sm"
                                id="Status"
                                name="selectStatus"
                                v-model="dataport.status"
                            >
                                <option disabled selected>
                                    {{ translateStatus(dataport.status) }}
                                </option>
                                <option
                                    v-for="(status, index) in $store.state
                                        .dataport.statuses"
                                    :key="index"
                                    :value="status.id"
                                >
                                    {{ status.value }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <div v-if="allowedToEditDataport">
                                <router-link
                                    :to="{
                                        path: '/editMyDataport/' + dataport.id,
                                    }"
                                >
                                    <div
                                        class="float-right"
                                        v-show="!dataport.editMode"
                                        v-tooltip="{
                                            content: 'Edit DataPort',
                                        }"
                                    >
                                        <i
                                            class="fas fa-pencil-alt"
                                        ></i>
                                    </div>
                                </router-link>
                            </div>

                            <div
                                v-if="dataport.archived"
                                class="float-right d-inline-block mr-2"
                                v-tooltip="{
                                    content: 'This DataPort is archived',
                                }"
                            >
                                <i class="fas fa-archive text-muted"></i>
                            </div>
                            <button
                                type="button"
                                class="btn btn-sm"
                                @click="saveChangedInput(dataport.id)"
                                v-show="dataport.editMode"
                            >
                                <i class="fas fa-check text-success"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-else class="mt-4">
            <div class="alert alert-primary" role="alert">
                No DataPorts found
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "myDataports",
    data() {
        return {
            sorted: false,
            ChangeList: [],
            showInactiveChecked: false,
            searchDataports: "",
            dataport: {},
        };
    },

    methods: {
        async startup() {
            var organisationProfileDTO = {};
            organisationProfileDTO.id = this.userProfile.organisationId;
            this.$store.dispatch("user/toggleLoadingMode");
            return this.$store
                .dispatch("dataport/loadDataports", organisationProfileDTO)
                .finally(this.$store.dispatch("user/toggleLoadingMode"));
        },

        translateStatus(sId) {
            return this.statuses.find((status) => status.id === sId).value;
        },

        toggleInactiveDataPorts() {
            this.showInactiveChecked = !this.showInactiveChecked;
        },

    },

    mounted() {
        this.startup();
    },
    computed: {
        ...mapGetters({
            dataportList: "dataport/getDataports",
            listings: "dataport/getListings",
            owners: "dataport/getOwners",
            statuses: "dataport/getStatuses",
            userProfile: "user/getUserProfile",
            userPrivilege: "user/getUserPrivilege",
        }),

        filteredList() {
            let dataports = this.dataportList
                .filter((dataport) => {
                    return (
                        (!this.showInactiveChecked && dataport.status !== 0) ||
                        this.showInactiveChecked
                    );
                })
                .sort((b, a) => new Date(a.created) - new Date(b.created));

            return dataports.filter((dataport) => {
                return (
                    dataport.id
                        .toLowerCase()
                        .match(this.searchDataports.toLowerCase()) ||
                    dataport.reference
                        .toLowerCase()
                        .match(this.searchDataports.toLowerCase()) ||
                    dataport.internalReference
                        .toLowerCase()
                        .match(this.searchDataports.toLowerCase())
                );
            });
        },
        allowedToEditDataport() {
            var privilege = this.userPrivilege;
            if (
                privilege.archiveDataPort == true ||
                privilege.editDataPort == true
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>

<style scoped>
.search-input {
    width: 20%;
}

.remove-addon-styling {
    background-color: #fff;
}

/* th,td {
  min-width: 200px;
} */

@media only screen and (max-width: 1140px) {
    .search-input {
        width: 40%;
    }
}

/* slider */
/* The switch - the box around the slider */

.active-switch .switch {
    line-height: 30px;
}

.active-switch label {
    margin-bottom: 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 5px;
    left: 0;
    right: 0;
    bottom: -5px;
    background-color: #ccc;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #007bff;
}

input:focus + .slider {
    box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
<style>
/* tooltip-vue styles */
.tooltip-vue {
    display: block !important;
    z-index: 10000;
    font-size: 14px;
}

.tooltip-vue .tooltip-vue-inner {
    background: black;
    color: white;
    border-radius: 4px;
    padding: 5px 10px 4px;
}

.tooltip-vue .tooltip-vue-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
}

.tooltip-vue[x-placement^="top"] {
    margin-bottom: 5px;
}

.tooltip-vue[x-placement^="top"] .tooltip-vue-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}

.tooltip-vue[x-placement^="bottom"] {
    margin-top: 5px;
}

.tooltip-vue[x-placement^="bottom"] .tooltip-vue-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}

.tooltip-vue[x-placement^="right"] {
    margin-left: 5px;
}

.tooltip-vue[x-placement^="right"] .tooltip-vue-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}

.tooltip-vue[x-placement^="left"] {
    margin-right: 5px;
}

.tooltip-vue[x-placement^="left"] .tooltip-vue-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}

.tooltip-vue.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip-vue.popover .popover-arrow {
    border-color: #f9f9f9;
}

.tooltip-vue[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
}

.tooltip-vue[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
}
</style>