<template>
    <div>
        <div class="row justify-content-center mt-5">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <router-link to="/dataportsWebsite">
                    <a> <i class="fas fa-chevron-left"></i>&nbsp;Back </a>
                </router-link>
                <div class="card mt-3">
                    <div class="card-body">
                        <div class="signup-header">
                            <span class="dp-logo">
                                <img src="../assets/dplogo.png" /> </span
                            >Organisation Details
                        </div>
                        <form>
                            <div class="form-group">
                                <label for="gpn">
                                    GPN
                                    <span class="required-asterisk">*</span>
                                    <small id="gpn" class="text-muted ml-1"
                                        >(Global Party Number)</small
                                    >
                                </label>
                                <a
                                    class="request-gpn float-right"
                                    @click="generateNewGPN()"
                                    >Request a GPN</a
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    id="gpn"
                                    placeholder="Your organisation's GPN"
                                    required
                                    v-model="generatedGPN"
                                />
                            </div>
                            <div class="form-group">
                                <label for="orgName">Organisation Name</label>
                                <span class="required-asterisk">*</span>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="orgName"
                                    v-model="organisationName"
                                    placeholder="Your Organisation's legal name"
                                    required
                                />
                            </div>

                            <!-- validate no placeholder -->
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">
                                    Country
                                    <span class="required-asterisk">*</span>
                                    <small
                                        id="countryHQ"
                                        class="text-muted ml-1"
                                        >Organisation's headquarters</small
                                    >
                                </label>
                                <select
                                    id="country"
                                    name="country"
                                    class="form-control"
                                    aria-describedby="countryHQ"
                                    @change="onChange($event)"
                                    required
                                >
                                    <option disabled selected>
                                        {{ country }}
                                    </option>
                                    <option value="Afganistan">
                                        Afghanistan
                                    </option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">
                                        American Samoa
                                    </option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antigua & Barbuda">
                                        Antigua & Barbuda
                                    </option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">
                                        Azerbaijan
                                    </option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">
                                        Bangladesh
                                    </option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bonaire">Bonaire</option>
                                    <option value="Bosnia & Herzegovina">
                                        Bosnia & Herzegovina
                                    </option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Ter">
                                        British Indian Ocean Ter
                                    </option>
                                    <option value="Brunei">Brunei</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">
                                        Burkina Faso
                                    </option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Canary Islands">
                                        Canary Islands
                                    </option>
                                    <option value="Cape Verde">
                                        Cape Verde
                                    </option>
                                    <option value="Cayman Islands">
                                        Cayman Islands
                                    </option>
                                    <option value="Central African Republic">
                                        Central African Republic
                                    </option>
                                    <option value="Chad">Chad</option>
                                    <option value="Channel Islands">
                                        Channel Islands
                                    </option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">
                                        Christmas Island
                                    </option>
                                    <option value="Cocos Island">
                                        Cocos Island
                                    </option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Cook Islands">
                                        Cook Islands
                                    </option>
                                    <option value="Costa Rica">
                                        Costa Rica
                                    </option>
                                    <option value="Cote DIvoire">
                                        Cote DIvoire
                                    </option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Curaco">Curacao</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">
                                        Czech Republic
                                    </option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">
                                        Dominican Republic
                                    </option>
                                    <option value="East Timor">
                                        East Timor
                                    </option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">
                                        El Salvador
                                    </option>
                                    <option value="Equatorial Guinea">
                                        Equatorial Guinea
                                    </option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands">
                                        Falkland Islands
                                    </option>
                                    <option value="Faroe Islands">
                                        Faroe Islands
                                    </option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">
                                        French Guiana
                                    </option>
                                    <option value="French Polynesia">
                                        French Polynesia
                                    </option>
                                    <option value="French Southern Ter">
                                        French Southern Ter
                                    </option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Great Britain">
                                        Great Britain
                                    </option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">
                                        Guadeloupe
                                    </option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Hawaii">Hawaii</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="India">India</option>
                                    <option value="Iran">Iran</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">
                                        Isle of Man
                                    </option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">
                                        Kazakhstan
                                    </option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea North">
                                        Korea North
                                    </option>
                                    <option value="Korea Sout">
                                        Korea South
                                    </option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">
                                        Kyrgyzstan
                                    </option>
                                    <option value="Laos">Laos</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libya">Libya</option>
                                    <option value="Liechtenstein">
                                        Liechtenstein
                                    </option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">
                                        Luxembourg
                                    </option>
                                    <option value="Macau">Macau</option>
                                    <option value="Macedonia">Macedonia</option>
                                    <option value="Madagascar">
                                        Madagascar
                                    </option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">
                                        Marshall Islands
                                    </option>
                                    <option value="Martinique">
                                        Martinique
                                    </option>
                                    <option value="Mauritania">
                                        Mauritania
                                    </option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Midway Islands">
                                        Midway Islands
                                    </option>
                                    <option value="Moldova">Moldova</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montserrat">
                                        Montserrat
                                    </option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">
                                        Mozambique
                                    </option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Nambia">Nambia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherland Antilles">
                                        Netherland Antilles
                                    </option>
                                    <option value="Netherlands">
                                        Netherlands (Holland, Europe)
                                    </option>
                                    <option value="Nevis">Nevis</option>
                                    <option value="New Caledonia">
                                        New Caledonia
                                    </option>
                                    <option value="New Zealand">
                                        New Zealand
                                    </option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">
                                        Norfolk Island
                                    </option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau Island">
                                        Palau Island
                                    </option>
                                    <option value="Palestine">Palestine</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">
                                        Papua New Guinea
                                    </option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Phillipines">
                                        Philippines
                                    </option>
                                    <option value="Pitcairn Island">
                                        Pitcairn Island
                                    </option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">
                                        Puerto Rico
                                    </option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Republic of Montenegro">
                                        Republic of Montenegro
                                    </option>
                                    <option value="Republic of Serbia">
                                        Republic of Serbia
                                    </option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russia">Russia</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="St Barthelemy">
                                        St Barthelemy
                                    </option>
                                    <option value="St Eustatius">
                                        St Eustatius
                                    </option>
                                    <option value="St Helena">St Helena</option>
                                    <option value="St Kitts-Nevis">
                                        St Kitts-Nevis
                                    </option>
                                    <option value="St Lucia">St Lucia</option>
                                    <option value="St Maarten">
                                        St Maarten
                                    </option>
                                    <option value="St Pierre & Miquelon">
                                        St Pierre & Miquelon
                                    </option>
                                    <option value="St Vincent & Grenadines">
                                        St Vincent & Grenadines
                                    </option>
                                    <option value="Saipan">Saipan</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="Samoa American">
                                        Samoa American
                                    </option>
                                    <option value="San Marino">
                                        San Marino
                                    </option>
                                    <option value="Sao Tome & Principe">
                                        Sao Tome & Principe
                                    </option>
                                    <option value="Saudi Arabia">
                                        Saudi Arabia
                                    </option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Seychelles">
                                        Seychelles
                                    </option>
                                    <option value="Sierra Leone">
                                        Sierra Leone
                                    </option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">
                                        Solomon Islands
                                    </option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">
                                        South Africa
                                    </option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">
                                        Switzerland
                                    </option>
                                    <option value="Syria">Syria</option>
                                    <option value="Tahiti">Tahiti</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">
                                        Tajikistan
                                    </option>
                                    <option value="Tanzania">Tanzania</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad & Tobago">
                                        Trinidad & Tobago
                                    </option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">
                                        Turkmenistan
                                    </option>
                                    <option value="Turks & Caicos Is">
                                        Turks & Caicos Is
                                    </option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="United Kingdom">
                                        United Kingdom
                                    </option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Erimates">
                                        United Arab Emirates
                                    </option>
                                    <option value="United States of America">
                                        United States of America
                                    </option>
                                    <option value="Uraguay">Uruguay</option>
                                    <option value="Uzbekistan">
                                        Uzbekistan
                                    </option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Vatican City State">
                                        Vatican City State
                                    </option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Vietnam">Vietnam</option>
                                    <option value="Virgin Islands (Brit)">
                                        Virgin Islands (Brit)
                                    </option>
                                    <option value="Virgin Islands (USA)">
                                        Virgin Islands (USA)
                                    </option>
                                    <option value="Wake Island">
                                        Wake Island
                                    </option>
                                    <option value="Wallis & Futana Is">
                                        Wallis & Futana Is
                                    </option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zaire">Zaire</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="vatId">VAT ID</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="vatId"
                                    v-model="vatID"
                                    placeholder="VAT ID"
                                />
                            </div>
                            <hr />
                            <div class="signup-header">Contact Information</div>
                            <div class="form-group">
                                <label for="full-Name">
                                    Full name
                                    <span class="required-asterisk">*</span>
                                    <small id="fullName" class="text-muted ml-1"
                                        >First & last name</small
                                    >
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="full-Name"
                                    v-model="fullName"
                                    placeholder="Your full name"
                                    aria-describedby="fullName"
                                    required
                                />
                            </div>

                            <!-- validate email -->
                            <div class="form-group">
                                <label for="contactEmail">
                                    Email
                                    <span class="required-asterisk">*</span>
                                    <small
                                        id="contactEmail"
                                        class="text-muted ml-1"
                                        >Email address of the primary contact
                                        person</small
                                    >
                                </label>
                                <div
                                    class="text-danger"
                                    v-if="!checkEmail && email.length > 4"
                                >
                                    Invalid email
                                </div>
                                <input
                                    type="email"
                                    class="form-control"
                                    id="contactEmail"
                                    v-model="email"
                                    placeholder="Email"
                                    aria-describedby="contactEmail"
                                    required
                                />
                            </div>
                            <div class="form-group">
                                <label for="contactPhone">Phone number</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <vue-country-code
                                            @onSelect="onSelect"
                                            :preferredCountries="[
                                                'us',
                                                'cn',
                                                'gb',
                                            ]"
                                            enabledCountryCode
                                        ></vue-country-code>
                                    </div>
                                    <input
                                        type="tel"
                                        class="form-control"
                                        id="contactPhone"
                                        placeholder="Phone number"
                                        v-model="phoneNumber"
                                    />
                                </div>
                            </div>
                            <button
                                v-if="!fieldsFilled"
                                type="button"
                                class="btn btn-primary float-right"
                                disabled
                            >
                                Create account
                            </button>
                            <router-link
                                to="/setPassword"
                                @click.native="saveInput"
                                v-else
                            >
                                <button
                                
                                    type="submit"
                                    class="btn btn-primary float-right"
                                >
                                    Create account
                                </button>
                            </router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { uuid } from "vue-uuid";
// import { ValidationProvider } from "vee-validate";
// import { ValidationObserver } from "vee-validate";
// import { extend } from "vee-validate";
// import { required, email, alpha_spaces } from "vee-validate/dist/rules";


// // Override message
// extend("email", {
//     ...email,
//     message: "This is not a valid email address",
// });

// extend("alpha_spaces", {
//     ...alpha_spaces,
//     message: "Only alphabetic characters and spaces are allowed",
// });

// extend("required", {
//     ...required,
// });

export default {
    name: "signUp",
    data() {
        return {
            generatedGPN: "",
            organisationName: "",
            country: "Choose country",
            vatID: "",
            fullName: "",
            email: "",
            phoneNumber: "",
            input: {
                ContactName: "",
                ContactEmail: "",
                ContactPhoneNumber: "",
                Password: "",
                OrganisationId: "",
                OrganisationName: "",
                CountryHQ: "",
                OrganisationGPN: "",
                VATId: "",
            },
        };
    },
    // components: {
    //     ValidationProvider,
    //     ValidationObserver,
    // },
    methods: {
        saveInput() {
            if (
                this.generatedGPN == "" ||
                this.organisationName == "" ||
                this.country == "Choose country" ||
                this.fullName == "" ||
                this.email == ""
            ) {
                console.log("Please fill in the required inputs");
            } else {
                this.input.ContactName = this.fullName;
                this.input.ContactEmail = this.email;
                this.input.ContactPhoneNumber = this.phoneNumber;
                this.input.OrganisationName = this.organisationName;
                this.input.CountryHQ = this.country;
                this.input.OrganisationGPN = this.generatedGPN;
                this.input.VATId = this.vatID;
                window.sessionStorage.Creation = JSON.stringify(this.input);
            }
        },
        onChange(event) {
            this.country = event.target.value;
        },
        onSelect() {
            // placeholder function to display registered data
            // console.log(name, iso2, dialCode);
        },
        generateNewGPN() {
            this.generatedGPN = uuid.v4();
        },
        generateGPN(length) {
            var result = "";
            var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            }
            let parts = result.match(/.{4}/g);
            var dashedValue = parts.join("-");
            this.generatedGPN = dashedValue;
        },
    },
    computed:{
        fieldsFilled() {
            //check email validity: needs any letters or numbers + '@' , text behind '@' + 2-4 characters after the '.'
            const checkValidEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            return (
                checkValidEmail.test(this.email) && this.generatedGPN.length>1 && this.organisationName.length>1 &&this.fullName.length>2 && this.country!=="Choose country"
            );
        },
        checkEmail() {
            const checkValidEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            return checkValidEmail.test(this.email);
        },
    },
};
</script>

<style scoped>
.required-asterisk {
    color: red;
}
.request-gpn {
    cursor: pointer;
}
.vue-country-select {
    background-color: #e9ecef;
    border-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
::v-deep .country-code {
    margin-left: 5px;
}
.dp-logo img {
    width: 36px;
    height: auto;
    float: right;
}
</style>