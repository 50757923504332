<template>
    <div id="app" v-cloak>
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :color="'#1fa4df'"
            :is-full-page="true"
        ></loading>
        <div class="container-fluid">
            <div class="row" v-if="isDashboard">
                <div class="col-md-2">
                    <sideNav></sideNav>
                </div>
                <div class="col-md-10">
                    <portalHeader></portalHeader>
                    <router-view></router-view>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-md-12">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sideNav from "./components/sideNavigation";
import portalHeader from "./components/portalHeader";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
export default {
    name: "App",
    components: {
        sideNav,
        portalHeader,
        Loading,
    },
    computed: {
        ...mapGetters({
            isLoading: "user/getIsloading",
        }),
        isDashboard() {
            if (this.$route.path === "/") return;
            if (this.$route.name === "dataportsWebsite") return;
            if (this.$route.name === "signUp") return;
            if (this.$route.name === "setPassword") return;
            if (this.$route.name === "requestNewPassword") return;
            if (this.$route.name === "resetPassword") return;
            if (this.$route.name === "signIn") return;
            return true;
        },
    },

    watch: {
        $route(to) {
            document.title = to.meta.title;
        },
    },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap");

#app {
    font-family: "Source Sans Pro", sans-serif;
    color: #333333;
}
[v-cloak] {
    display: none;
}
.button-icon {
    margin-right: 5px;
}
.content-header {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 10px;
}
.boldText {
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    .mobile-margin-top {
        margin-top: 2em;
    }
}
.page-title {
    font-size: 24px;
    font-weight: bold;
}
.signup-header {
    font-weight: bold;
    color: #707070;
    font-size: 24px;
    margin-bottom: 20px;
}
</style>
