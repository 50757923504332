import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VTooltip from 'v-tooltip'
import VueCookies from "vue-cookies";
import UUID from 'vue-uuid'

// Font Awesome 5
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

// Bootstrap/jquery/popper
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;
import Popper from 'popper.js';
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

// country code checker 
import VueCountryCode from "vue-country-code";

// Utils
import {
    store
} from './utils/store.js';
import router from './utils/router/router.js';

// Use
Vue.use(VueRouter);
Vue.use(VueCountryCode);
Vue.use(VueCookies);
Vue.use(VTooltip);
Vue.use(UUID);
VTooltip.options.defaultTemplate = '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>';
VTooltip.options.defaultArrowSelector = '.tooltip-vue-arrow, .tooltip-vue__arrow';
VTooltip.options.defaultInnerSelector = '.tooltip-vue-inner, .tooltip-vue__inner';

Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
    VueRouter,
    store,
    router,
    render: h => h(App)
}).$mount('#app')