import axios from 'axios';

export class APIService {

    constructor() {


    }

}

// import {
//     BlobServiceClient
    
// } from "@azure/storage-blob"
// import {
//     uuid
// } from 'vue-uuid'

export default {



    data: function () {
        return {
            blobServiceClient: null
        };

    },
    methods: {
        async signIn(url,email,password){
            const destination =process.env.VUE_APP_BASE_URL + url;
            return await axios.post(destination,{data:{contactEmail:email,password:password}})
        },
        async loadOwnerDataports(url) {
            const destination = process.env.VUE_APP_BASE_URL + url;
            return await axios.get(destination);
        },
        async sentGetRequestNoBody(url) {
            const destination = process.env.VUE_APP_BASE_URL + url;
            return await axios.get(destination)
        },
        async sentPOSTRequestWithBody(url, DTO) {
            const destination = process.env.VUE_APP_BASE_URL + url;
            return await axios.post(destination, DTO);
        },
        async sentDELETERequestWithBody(url, DTO) {
            const destination = process.env.VUE_APP_BASE_URL + url;
            return await axios.delete(destination, {
                data: DTO
            }, {
                crossdomain: true
            }).then(response => {
                this.response = response
            });
        },
 

        }
    }