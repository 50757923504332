import api from '../apis/api.js';
const authorisation = {
    namespaced: true,
    state: {

        authorisations: [],
        authorisationStatusList: [],
        authorisationBasedOnSearchResult: [],
        searchResult: [],
        ownedDataPorts: [],

    },

    // getters
    getters: {
        getAuthorisationById: (state) => (id) => {
            return state.authorisations.find(auth => auth.id === id);
        },
        getAuthorizedDataports(state) {
            return state.authorisations;
        },
        getAuthorizationStatusList(state) {
            return state.authorisationStatusList;
        },
        getAuthorisationBasedOnSearchResult(state) {
            return state.authorisationBasedOnSearchResult;
        },
        getSearchResult(state) {
            return state.searchResult;
        },
        getOwnedDataPorts(_state, _getters, _rootState, rootGetters) {
            return rootGetters['dataport/getDataports']
        }
    },


    // actions

    actions: {

        async loadauthorisations(state, {
            DTO,
            dataPorts
        }) {
            return api.methods.sentPOSTRequestWithBody("/LoadAuthorisation", DTO).then((response) => {
                var data = response.data;
                state.commit("updateauthorisations", {
                    data,
                    dataPorts
                })
            });

        },
        async loadAuthorisedStatusList(state) {
            return api.methods.sentGetRequestNoBody("/LoadAuthorisationStatusList").then(response => {
                state.commit("updateAuthorisationStatusList", response.data);
            });
        },
        async loadAuthorisationBasedOnSearchResult(state) {
            return api.methods.sentGetRequestNoBody("/LoadAuthorisationBasedOnSearchResult").then(response => {
                state.commit("updateAuthorisationBasedOnSearchResult", response.data);
            });
        },
        searchDataPorts(state, data) {
            state.commit("updateSearchDataPorts", data);
        },
        async updateAuthorisation(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/UpdateAuthorisation", DTO)
        },
        async requestAuthorisation(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/RequestAuthorisation", DTO)
        },
        async acceptAuthorisationRequest(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/AcceptAuthorisation", DTO)
        },
        async declineAuthorisationRequest(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/DeclineAuthorisation", DTO)
        },
        async revokeAuthorisation(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/RevokeAuthorisation", DTO)
        },
        async removeAuthorisation(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/RemoveAuthorisation", DTO)
        },


    },

    // mutations
    mutations: {

        updateauthorisations(state, {
            data,
            dataPorts
        }) {
            state.authorisations = data;
            for (let index = 0; index < dataPorts.length; index++) {
                for (let i = 0; i < state.authorisations.length; i++) {
                    if (state.authorisations[i].destinationDataPortId === dataPorts[index].id) {
                        state.authorisations[i].owned = true;
                        continue 
                    }
                    
                }

            }
        },
        updateAuthorisationStatusList(state, data) {
            state.authorisationStatusList = data;
        },
        updateAuthorisationBasedOnSearchResult(state, data) {
            state.authorisationBasedOnSearchResult = data
        },
        updateSearchDataPorts(state, data) {
            state.searchResult = data;
        },
        updateOwnedDataPorts(state, data) {
            state.ownedDataPorts = data;
        }
    },
}

export default authorisation