<template>
    <div>
        <span class="badge badge-danger"> Sample Data </span>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    id="organisation-tab"
                    data-toggle="tab"
                    href="#organisation"
                    role="tab"
                    aria-controls="Organisation"
                    aria-selected="false"
                    >Organisation</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    id="locations-tab"
                    data-toggle="tab"
                    href="#locations"
                    role="tab"
                    aria-controls="Locations"
                    aria-selected="false"
                    >Locations</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    id="brands-tab"
                    data-toggle="tab"
                    href="#brands"
                    role="tab"
                    aria-controls="Brands"
                    aria-selected="false"
                    >Brands</a
                >
            </li>
        </ul>
        <div class="tab-content">
            <div
                class="tab-pane fade show active"
                id="organisation"
                role="tabpanel"
                aria-labelledby="Organisation"
            >
                <div class="row">
                    <div class="col-md-12">
                        <button
                            type="button"
                            class="btn btn-success btn-sm mt-4 float-right"
                        >
                            <i class="fas fa-plus"></i>&nbsp;Organisation
                        </button>
                    </div>
                </div>
                <div class="mt-4" v-if="organisations">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">GPN</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(entity, index) in organisations"
                                :key="index"
                            >
                                <td
                                    v-bind:class="{
                                        boldText:
                                            entity.leadOrganisation === true,
                                    }"
                                >
                                    {{ entity.name }}
                                </td>
                                <td>{{ entity.gpn }}</td>
                                <td>
                                    <button
                                        type="button"
                                        class="btn btn-sm text-primary float-right"
                                    >
                                        <i class="fas fa-pencil-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="mt-2">
                    <div class="alert alert-primary" role="alert">
                        No DataPorts added yet
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="locations"
                role="tabpanel"
                aria-labelledby="Locations"
            >
                <div class="row">
                    <div class="col-md-12">
                        <button
                            type="button"
                            class="btn btn-success btn-sm mt-4 float-right"
                        >
                            <i class="fas fa-plus"></i>&nbsp;Location
                        </button>
                    </div>
                </div>
                <div class="mt-4" v-if="locations">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">GPN</th>
                                <th scope="col">Type</th>
                                <th scope="col">Reference</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(location, index) in locations"
                                :key="index"
                            >
                                <td>{{ location.name }}</td>
                                <td>{{ location.gpn }}</td>
                                <td>{{ location.type }}</td>
                                <td>{{ location.reference }}</td>
                                <td>
                                    <a
                                        class="passport-link"
                                        v-if="location.passport"
                                        :href="location.passport"
                                    >
                                        <i class="far fa-eye"></i>Facility
                                        Passport
                                    </a>
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        class="btn btn-sm text-primary float-right"
                                    >
                                        <i class="fas fa-pencil-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="mt-4">
                    <div class="alert alert-primary" role="alert">
                        No locations added yet
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="brands"
                role="tabpanel"
                aria-labelledby="Brands"
            >
                <div class="row">
                    <div class="col-md-12">
                        <button
                            type="button"
                            class="btn btn-success btn-sm mt-4 float-right"
                        >
                            <i class="fas fa-plus"></i>&nbsp;Brand
                        </button>
                    </div>
                </div>
                <div class="mt-4 mb-4" v-if="brands">
                    <div
                        class="brand-block"
                        v-for="(brand, index) in brands"
                        :key="index"
                    >
                        <div class="brand-image-background">
                            <img class="brand-image" :src="brand.brandLogo" />
                        </div>
                        <div class="mt-3 boldText">
                            {{ brand.brandName }}
                            <button
                                type="button"
                                class="btn btn-sm text-primary float-right"
                            >
                                <i class="fas fa-pencil-alt"></i>
                            </button>
                        </div>
                        <div class="brand-gbin text-muted">
                            GBIN:&nbsp;{{ brand.gbin }}
                        </div>
                    </div>
                </div>
                <div v-else class="mt-4">
                    <div class="alert alert-primary" role="alert">
                        No brands added yet
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "organisation",
    data() {
        return {};
    },
    methods: {
        addOrganisation() {
            console.log("Add organisation clicked");
        },
        addLocation() {
            console.log("Add location clicked");
        },
        addBrand() {
            console.log("Add brand clicked");
        },
    },

    computed: {
        ...mapGetters({
            brands: "organisation/getBrands",
            locations: "organisation/getLocations",
            organisations: "organisation/getOrganisations",
            getUserCanEdit: "user/getUserCanEdit",
        }),
    },
    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>

<style scoped>
.passport-link {
    color: #1964d5;
}
.passport-link .fa-eye {
    margin-right: 5px;
}
.brand-block {
    width: calc(100% - 14px);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin: 7px;
    padding: 15px;
    position: relative;
    border: 3px solid rgba(92, 183, 91, 0);
    border-radius: 4px;
    display: inline-block;
}
.brand-image-background {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 128px;
    background-color: #f8f8f8;
}
.brand-image {
    max-width: 100%;
    max-height: 128px;
}

@media (min-width: 768px) {
    .brand-block {
        width: calc(49.95% - 14px);
    }
}

@media (min-width: 992px) {
    .brand-block {
        width: calc(33.33% - 14px);
    }
}

@media screen and (min-width: 1200px) {
    .brand-block {
        width: calc(24.95% - 14px);
    }
}

@media screen and (min-width: 1600px) {
    .brand-block {
        width: calc(16.666% - 14px);
    }
}
</style>