<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <router-link to="/authorizations">
                    <a href="#">
                        <i class="fas fa-chevron-left"></i>&nbsp;Back
                    </a>
                </router-link>
                <button
                    type="button"
                    class="btn btn-sm btn-success float-right"
                    data-toggle="modal"
                    data-target="#addGpn"
                >
                    <i class="fas fa-plus"></i>&nbsp;Add GPN to blacklist
                </button>
                <div
                    class="modal fade"
                    id="addGpn"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="blacklistModal"
                    aria-hidden="true"
                >
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="blacklistModal">
                                    Add a GPN to your blacklist
                                </h5>
                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Search for a GPN to blacklist"
                                    aria-label="Search"
                                    autocomplete="off"
                                    v-model="searchInput"
                                    @input="filterGPNs"
                                />
                                <div
                                    v-if="
                                        filteredBlacklist.length !== 0 &&
                                        searchResultsActive
                                    "
                                >
                                    <ul
                                        class="list-group list-group-flush mt-2 search-results"
                                    >
                                        <li
                                            class="list-group-item"
                                            v-for="(result,
                                            index) in filteredBlacklist"
                                            :key="index"
                                            @click="setBlacklist(result)"
                                        >
                                            GPN:&nbsp;{{
                                                result.GPN
                                            }}&nbsp;|&nbsp;Organisation:&nbsp;{{
                                                result.name
                                            }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="addToBlacklist"
                                >
                                    Add to blacklist
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="dataports-table table-responsive mt-4"
            v-if="authorizations.length > 0"
        >
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">GPN</th>
                        <th scope="col">Organisation name</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(authorization, index) in authorizations"
                        :key="index"
                    >
                        <td>{{ authorization.timeStamp }}</td>
                        <td>{{ authorization.gpn }}</td>
                        <td>{{ authorization.orgName }}</td>
                        <td>
                            <div v-if="authorization.status === 4">
                                <span class="badge badge-dark">
                                    <i class="fas fa-ban"></i>&nbsp;Blacklisted
                                </span>
                            </div>
                        </td>
                        <td>
                            <div v-if="authorization.status === 4">
                                <button
                                    type="button"
                                    class="btn btn-sm text-danger float-right"
                                >
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm text-primary float-right"
                                >
                                    <i class="fas fa-pencil-alt"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="no-dataports-added">
            <div class="alert alert-primary" role="alert">
                No blacklisted GPNs added yet
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "blacklistGPN",
    data() {
        return {
            searchInput: "",
            searchResultsActive: false,
            authorizedFilterKey: "showPendingList",
            authorizations: [
                {
                    timeStamp: "24 June 2020 - 10:41",
                    gpn: "4597342952",
                    orgName: "Enron",
                    status: 4,
                },
            ],
            blacklist: [
                {
                    name: "Blockbuster",
                    GPN: "1234567890",
                },
                {
                    name: "JCPenny",
                    GPN: "6573423231",
                },
            ],
            filteredBlacklist: [],
        };
    },
    methods: {
        filterGPNs() {
            this.filteredBlacklist = this.blacklist.filter((value) => {
                return (
                    value.GPN.startsWith(this.searchInput) ||
                    value.name
                        .toLowerCase()
                        .startsWith(this.searchInput.toLowerCase())
                );
            });
            this.searchResultsActive = true;
        },

        setBlacklist(result) {
            this.searchInput = `${result.GPN} | ${result.name}`;
            this.searchResultsActive = false;
        },

        addToBlacklist() {
            // vuex logic to blacklist search result

            // clear input after adding and refresh the page (not sure if refreshing is necessary)
            this.searchInput = "";
            this.$router.go();
        },
    },
    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>
<style scoped>
.search-results:hover {
    cursor: pointer;
}
.search-results li:hover {
    background-color: #007bff;
    color: #fff;
}
</style>