import api from '../apis/api.js';

const organisation = {
    namespaced: true,
    state: {
        brands: [{
                brandName: "Alibaba.com",
                gbin: "1232173606",
                brandLogo: "https://lh3.googleusercontent.com/Btfes5_yrMt5peCLS3QDjKYZN9rUYqGdPxrmBXdJ0b-6SJFI5-JLd5QSHl4aAplt_FE",
            },
            {
                brandName: "Alibaba Cloud",
                gbin: "1232244931",
                brandLogo: "https://mms.businesswire.com/media/20190925005325/en/717641/23/orange_logo-02.jpg",
            },
        ],
        locations: [{
            gpn: "8719328024200",
            name: "Alibaba.com",
            type: "GLN",
            reference: "China HQ - Hangzhou",
            passport: "#",
        }, ],
        organisations: [{
                name: "Alibaba Group",
                gpn: "03f538ad-eda6-9734-845f-akf36d70cd9",
                leadOrganisation: true
            },
            {
                name: "Alibaba.com",
                gpn: "6cec6346-94ee-4d97-98e8-679ab2b97229",
                leadOrganisation: false,
            },
            {
                name: "Alibaba Cloud",
                gpn: "0eeg4223-61cf-3e76-44i9-563ab2lk0446",
                leadOrganisation: false,
            },
        ],
        organisationProfile: {
            organisationId: "",
            organisationName: "",
            countryHQ: "",
            organisationGPN: "",
            VATId: ""
        },
    },

    // getters
    getters: {
        getBrands(state) {
            return state.brands;
        },
        getOrganisations(state) {
            return state.organisations;
        },
        getLocations(state) {
            return state.locations;
        },
        getOrganisationProfile(state) {
            return state.organisationProfile;
        },
        getSelectedUserProfile(state, index) {
            return state.organisationUsers[index];
        },
    },

    // actions
    actions: {
        async updateOrganisationProfile(_state,DTO){
            return api.methods.sentPOSTRequestWithBody("/updateorganisationprofile",DTO)
        },

        async loadOrganisationProfile(state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/getOrganisationprofile", DTO).then(response => {
                state.commit("updateOrganisationProfile", response.data);
            })
        },
        async prepareDashboard(state, data) {
            await state.commit("prepareOrganisationDTO", data)
        },
        async registerOrganisationAndUser(_state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/registerOrganisationAndUser", DTO);
        },
        async saveOrganisationProfile(state, DTO) {
            return api.methods.sentPOSTRequestWithBody("/updateorganisationprofile", DTO).then(state.commit("updateOrganisationProfile", DTO))
        },
    },

    // mutations
    mutations: {
        updateOrganisationProfile(state, data) {
            state.organisationProfile = data;
        },

    }
}

export default organisation