var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row justify-content-center mt-5"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-4"},[_c('router-link',{attrs:{"to":"/dataportsWebsite"}},[_c('a',[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" Back")])]),_c('div',{staticClass:"card mt-3"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"signup-header"},[_vm._v("Set your password")]),_c('ul',{staticClass:"list-unstyled text-muted"},[_c('li',{class:{
                                    isValid: _vm.containsEightCharacters,
                                }},[_vm._v(" At least 8 characters ")]),_c('li',{class:{
                                    isValid: _vm.containsNumber,
                                }},[_vm._v(" Contains a number ")]),_c('li',{class:{
                                    isValid: _vm.containsUppercase,
                                }},[_vm._v(" Contains an uppercase character ")]),_c('li',{class:{
                                    isValid: _vm.containsSpecialCharacter,
                                }},[_vm._v(" Contains a special character ")])]),_c('div',{staticClass:"input-group-append"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"passwordInput",staticClass:"form-control remove-input-border",attrs:{"id":"setNewPassword","type":"password","autocomplete":"new-password","placeholder":"Set a password"},domProps:{"value":(_vm.password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.password=$event.target.value},_vm.checkPassword]}}),_c('span',{staticClass:"input-group-append input-group-text remove-addon-styling",on:{"click":_vm.togglePassword}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassword),expression:"!showPassword"}],staticClass:"password-toggle"},[_c('i',{staticClass:"fas fa-eye-slash"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPassword),expression:"showPassword"}],staticClass:"password-toggle"},[_c('i',{staticClass:"fas fa-eye text-primary"})])])])]),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"type":"submit","disabled":!_vm.validPassword},on:{"click":_vm.setPassword}},[_vm._v(" Set password ")])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }