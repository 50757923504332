<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <span class="page-title float-left">My Account</span>
            </div>
            <div class="col-md-12"></div>
        </div>
        <div class="row">
            <div class="col-md-6 mt-4 mb-4" v-if="userProfile.admin == 1">
                <div class="profile-header mb-2">
                    Organisation Details
                    <button
                        type="button"
                        class="btn btn-success btn-sm float-right"
                        v-if="userProfile.admin == true"
                        @click="saveOrganisationProfile"
                    >
                        <i class="fas fa-save"></i>&nbsp;Save Organisation
                    </button>
                </div>
                <form>
                    <div class="form-group">
                        <label for="orgName">Organisation name</label>
                        <input
                            type="text"
                            class="form-control"
                            id="orgName"
                            placeholder="Your organisation's legal name"
                            v-model="organisationProfile.organisationName"
                            required
                        />
                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlSelect1">Country</label>
                        <small id="countryHQ" class="text-muted ml-2"
                            >Organisation's headquarters</small
                        >
                        <select
                            id="country"
                            name="country"
                            class="form-control"
                            aria-describedby="countryHQ"
                            v-model="organisationProfile.countryHQ"
                            required
                        >
                            <option value="Country">Country name</option>
                            <option value="Afganistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">
                                American Samoa
                            </option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antigua & Barbuda">
                                Antigua & Barbuda
                            </option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bonaire">Bonaire</option>
                            <option value="Bosnia & Herzegovina">
                                Bosnia & Herzegovina
                            </option>
                            <option value="Botswana">Botswana</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Ter">
                                British Indian Ocean Ter
                            </option>
                            <option value="Brunei">Brunei</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Canary Islands">
                                Canary Islands
                            </option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">
                                Cayman Islands
                            </option>
                            <option value="Central African Republic">
                                Central African Republic
                            </option>
                            <option value="Chad">Chad</option>
                            <option value="Channel Islands">
                                Channel Islands
                            </option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">
                                Christmas Island
                            </option>
                            <option value="Cocos Island">Cocos Island</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote DIvoire">Cote DIvoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curaco">Curacao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">
                                Czech Republic
                            </option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">
                                Dominican Republic
                            </option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">
                                Equatorial Guinea
                            </option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands">
                                Falkland Islands
                            </option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">
                                French Polynesia
                            </option>
                            <option value="French Southern Ter">
                                French Southern Ter
                            </option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Great Britain">Great Britain</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="India">India</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea North">Korea North</option>
                            <option value="Korea Sout">Korea South</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">
                                Marshall Islands
                            </option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Midway Islands">
                                Midway Islands
                            </option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Nambia">Nambia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherland Antilles">
                                Netherland Antilles
                            </option>
                            <option value="Netherlands">
                                Netherlands (Holland, Europe)
                            </option>
                            <option value="Nevis">Nevis</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">
                                Norfolk Island
                            </option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau Island">Palau Island</option>
                            <option value="Palestine">Palestine</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">
                                Papua New Guinea
                            </option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Phillipines">Philippines</option>
                            <option value="Pitcairn Island">
                                Pitcairn Island
                            </option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Republic of Montenegro">
                                Republic of Montenegro
                            </option>
                            <option value="Republic of Serbia">
                                Republic of Serbia
                            </option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="St Barthelemy">St Barthelemy</option>
                            <option value="St Eustatius">St Eustatius</option>
                            <option value="St Helena">St Helena</option>
                            <option value="St Kitts-Nevis">
                                St Kitts-Nevis
                            </option>
                            <option value="St Lucia">St Lucia</option>
                            <option value="St Maarten">St Maarten</option>
                            <option value="St Pierre & Miquelon">
                                St Pierre & Miquelon
                            </option>
                            <option value="St Vincent & Grenadines">
                                St Vincent & Grenadines
                            </option>
                            <option value="Saipan">Saipan</option>
                            <option value="Samoa">Samoa</option>
                            <option value="Samoa American">
                                Samoa American
                            </option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome & Principe">
                                Sao Tome & Principe
                            </option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">
                                Solomon Islands
                            </option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Tahiti">Tahiti</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad & Tobago">
                                Trinidad & Tobago
                            </option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks & Caicos Is">
                                Turks & Caicos Is
                            </option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="United Kingdom">
                                United Kingdom
                            </option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Erimates">
                                United Arab Emirates
                            </option>
                            <option value="United States of America">
                                United States of America
                            </option>
                            <option value="Uraguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City State">
                                Vatican City State
                            </option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Virgin Islands (Brit)">
                                Virgin Islands (Brit)
                            </option>
                            <option value="Virgin Islands (USA)">
                                Virgin Islands (USA)
                            </option>
                            <option value="Wake Island">Wake Island</option>
                            <option value="Wallis & Futana Is">
                                Wallis & Futana Is
                            </option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zaire">Zaire</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="gpn">GPN</label>
                        <input
                            type="text"
                            class="form-control"
                            id="gpn"
                            placeholder="Your organisation's GPN"
                            v-model="organisationProfile.GPN"
                            required
                            readonly
                        />
                    </div>
                    <div class="form-group">
                        <label for="vatId">VAT ID</label>
                        <input
                            type="text"
                            class="form-control"
                            id="vatId"
                            placeholder="VAT ID"
                            v-model="organisationProfile.VATId"
                            required
                            readonly
                        />
                    </div>
                </form>
            </div>
            <div class="col-md-6 mt-4 mb-4">
                <div class="profile-header mb-2">
                    Contact Information
                    <button
                        type="button"
                        class="btn btn-success btn-sm float-right"
                        @click="saveProfile"
                    >
                        <i class="fas fa-save"></i>&nbsp;Save Account
                    </button>
                </div>
                <div class="form-group">
                    <label for="full-Name">Full name</label>
                    <small id="fullName" class="text-muted ml-2"
                        >First & last name</small
                    >
                    <input
                        type="text"
                        class="form-control"
                        id="full-Name"
                        placeholder="Your full name"
                        aria-describedby="fullName"
                        v-model="userProfile.name"
                        required
                    />
                </div>
                <div class="form-group">
                    <label for="contactEmail">Email</label>
                    <input
                        type="email"
                        class="form-control"
                        id="contactEmail"
                        placeholder="Email"
                        aria-describedby="contactEmail"
                        v-model="userProfile.email"
                        required
                    />
                </div>
                <div class="form-group">
                    <label for="contactPhone">Phone number</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <vue-country-code
                                @onSelect="onSelect"
                                defaultCountry=""
                                :preferredCountries="['us', 'cn', 'gb']"
                                enabledCountryCode
                            ></vue-country-code>
                        </div>
                        <input
                            type="tel"
                            class="form-control"
                            id="contactPhone"
                            placeholder="Phone number"
                            v-model="userProfile.phoneNumber"
                            required
                        />
                    </div>
                </div>

                <!-- <div class="form-group">
                    <label for="profilePicture">Profile picture </label
                    ><small class="text-muted ml-2"
                        >Supported images: .png, .jpg. or .jpeg under 2
                        MB</small
                    >
                    <div class="custom-file">
                        <input
                            type="file"
                            class="custom-file-input"
                            id="profilePicture"
                            @change="onFileSelected"
                            accept=".png, .jpg, .jpeg"
                        />

                        <label
                            class="custom-file-label"
                            for="profilePicture"
                            v-html="fileName"
                            >{{ this.fileName }}</label
                        >
                    </div>
                    
                    <div class="mt-2" v-if="previewImage">
                        <div
                            v-show="exceededFileSize"
                            class="alert alert-warning mt-2"
                            role="alert"
                        >
                            The image you want to upload exceeds to maximum file
                            size of&nbsp;<span class="font-weight-bold"
                                >2MB</span
                            >
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card">
                                    <div class="card-body">
                                        <img :src="previewImage" />
                                        <div
                                            class="image-button-container mt-3"
                                        >
                                            <button
                                                v-if="uploadButtonValid"
                                                type="button"
                                                class="btn btn-primary btn-sm mr-1"
                                                @click="uploadProfilepicture"
                                            >
                                                <i
                                                    class="fas fa-cloud-upload-alt"
                                                ></i
                                                >&nbsp;Upload
                                            </button>
                                            <button
                                                v-else
                                                type="button"
                                                class="btn btn-primary btn-sm mr-1"
                                                disabled
                                            >
                                                <i
                                                    class="fas fa-cloud-upload-alt"
                                                ></i
                                                >&nbsp;Upload
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-danger btn-sm"
                                                @click="deleteProfilePic"
                                            >
                                                <i class="fas fa-trash-alt"></i>
                                                &nbsp;Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
// import { ValidationProvider } from "vee-validate";
// import { ValidationObserver } from "vee-validate";
// import { extend } from "vee-validate";
// import { required, email, alpha_spaces } from "vee-validate/dist/rules";

// // Override message
// extend("email", {
//     ...email,
//     message: "This is not a valid email address",
// });

// extend("alpha_spaces", {
//     ...alpha_spaces,
//     message: "Only alphabetic characters and spaces are allowed",
// });

// extend("required", {
//     ...required,
// });

export default {
    name: "myAccount",
    data() {
        return {
            selectedFile: null,
            fileName: "Select a picture",
            previewImage: null,
            image: "",
            uploadButtonValid: false,
            exceededFileSize: false,
            container: "profilepictures",
            userProfileDTO: {},
            organisationProfileDTO: {},
            dialCodeHolder: "",
        };
    },
    // components: {
    //     ValidationProvider,
    //     ValidationObserver,
    // },
    methods: {
        onSelect({ dialCode }) {
            this.dialCodeHolder = dialCode;
        },

        deleteProfilePic() {
            console.log("deleted");
        },

        onFileSelected(event) {
            let selectedFile = event.target.files[0];
            let selectedFileSize = event.target.files[0].size / 1024 / 1024;
            selectedFileSize = selectedFileSize.toFixed(2);

            this.fileName = event.target.value.split("\\").pop();
            this.previewImage = URL.createObjectURL(selectedFile);
            this.selectedFile = selectedFile;

            if (selectedFileSize > 2) {
                this.uploadButtonValid = false;
                this.exceededFileSize = true;
            } else {
                this.uploadButtonValid = true;
                this.exceededFileSize = false;
            }
        },
        saveProfile() {
            this.$store
                .dispatch("user/editprofile", this.userProfile)
                .then(() => {
                    return this.$store.dispatch(
                        "user/loadUserProfile",
                        this.userProfile
                    );
                });
        },
        saveOrganisationProfile() {
            this.organisationProfileDTO.id = this.organisationProfile.id;
            this.organisationProfileDTO.name = this.organisationProfile.name;
            this.organisationProfileDTO.countryHQ = this.organisationProfile.countryHQ;
            this.organisationProfileDTO.GPN = this.organisationProfile.GPN;
            this.organisationProfileDTO.VATId = this.organisationProfile.VATId;
            this.$store
                .dispatch(
                    "organisation/updateOrganisationProfile",
                    this.organisationProfileDTO
                )
                .then(() => {
                    return this.$store.dispatch(
                        "organisation/loadOrganisationProfile",
                        this.organisationProfile
                    );
                });
            //.finally(this.$router.go());
        },

        fileAdded(File) {
            try {
                this.$store.dispatch("user/uploadProfilePicture", File);
            } catch (error) {
                console.log(error);
            }
        },
    },

    computed: {
        ...mapGetters({
            userProfile: "user/getUserProfile",
            organisationProfile: "organisation/getOrganisationProfile",
            profilePicture: "user/getProfilePicture",
        }),
    },

    beforeRouteEnter(to, from, next) {
        let access_token = window.sessionStorage.getItem("vuex");
        if (access_token === null) {
            // user doesn't have access token, redirect to login
            next({ name: "signIn" });
        } else {
            // user has access token, user can open the page
            next();
        }
    },
};
</script>
<style>
.profile-header {
    font-weight: bold;
    color: #707070;
    font-size: 18px;
}
</style>
<style scoped>
.custom-file-input {
    cursor: pointer;
}

::v-deep .country-code {
    margin-left: 5px;
}
.vue-country-select {
    background-color: #e9ecef;
    border-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
::v-deep .vue-country-select .dropdown-list {
    z-index: 99;
}

.card img {
    object-fit: cover;
    width: 100%;
    height: 195px;
    border: 2px solid #1d344d;
    border-radius: 50%;
}
.image-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>